import Input from "components/inputs/Input";
import filter from "assets/filter.svg";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDocumentTitle } from "hooks/useDocument";
import { useFetchKYCs } from "./useKycHandlers";
import AccessDenied from "components/AccessDenied";
import { AnyObject, ContextType } from "utils/types";
import Loader from "components/Loader";
import CsvButton from "components/CsvButton";
import { businessKYC, personalKYC } from "utils/tableHeaders";
import { convertDate } from "utils/fx";
import { useAuthContext } from "hooks/useAuthContext";
import { useDebounce } from "hooks/useDebounce";

const tabs = ["", "business"];

type tabType = (typeof tabs)[number];

export default function KYCLayout() {
  const param = useParams<Record<"*", tabType>>();
  const [tab, setTab] = useState<tabType>("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const {user } = useAuthContext()
  const navigate = useNavigate();

  const location = useLocation();

  useDocumentTitle(`KYC - ${tab === "" ? "Personal" : tab}`);

  const { data, error, isLoading } = useFetchKYCs(
    page,
    tab === "" ? "individual" : "business",
    useDebounce(searchTerm)
  );

  const canViewKYC = user?.permissions?.includes('view:kyc')
  useEffect(() => {
    if(!canViewKYC && !isLoading) {
      navigate('/home')
    }
  }, [canViewKYC, isLoading, navigate])

  useEffect(() => {
    if (param["*"]) {
      if (tabs.includes(param["*"].split("/")[1])) {
        setTab(param["*"].split("/")[1]);
      } else {
        setTab("");
      }
    }
  }, [param]);

  useEffect(() => {
    setPage(1);
  }, [location.pathname]);

 

  if (isLoading) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  const csvData = [tab === "" ? personalKYC : businessKYC];

  const getCSVData = () => {
    if (data?.results?.length === 0) {
      return [];
    }
    if (tab === "") {
      for (let i = 0; i < data?.results?.length; i++) {
        let arr = [];
        arr.push(data?.results[i]?.status);
        arr.push(
          `${data?.results[i]?.user?.firstName} ${
            data?.results[i]?.user?.middleName ?? ""
          } ${data?.results[i]?.user?.lastName}`
        );
        arr.push(`Tier ${data?.results[i]?.currentAccountTier}`);
        arr.push(`Tier ${data?.results[i]?.requestedTier}`);
        arr.push(
          `${data?.results[i]?.user?.dialCode} ${data?.results[i]?.user?.phoneNumber}`
        );
        arr.push(data?.results[i]?.user?.email);
        arr.push(`${convertDate(data?.results[i]?.createdAt)}`);

        csvData.push(arr);
      }
    } else {
      for (let i = 0; i < data?.results?.length; i++) {
        let arr = [];
        arr.push(data?.results[i]?.status);
        arr.push(`${data?.results[i]?.companyName}`);

        arr.push(`${data?.results[i]?.rcNumber}}`);
        arr.push(`${convertDate(data?.results[i]?.createdAt)}`);

        csvData.push(arr);
      }
    }
    return csvData;
  };

  return (
    <>
      <div className="pt-2 flex justify-start items-center gap-8 ml-16 font-medium">
        <Link
          to=""
          role="tab"
          className={`pb-4 cursor-pointer ${
            tab === ""
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          }`}
        >
          Personal
        </Link>
        <Link
          to="business"
          role="tab"
          className={`pb-4 cursor-pointer ${
            tab === "business"
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          }`}
        >
          Business
        </Link>
      </div>
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">
          KYC - {data?.totalResults}
        </p>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div className="flex gap-2 items-center mt-2">
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Account Name or Account Number"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          {data?.results?.length > 0 && (
            <div className="flex gap-2 justify-end ml-36">
              <CsvButton
                data={getCSVData()}
                filename={
                  tab === ""
                    ? `individual_kyc_${page}.csv`
                    : `business_kyc_${page}.csv`
                }
              />
            </div>
          )}
        </div>
      </div>
      <Outlet context={{ data, next, prev } satisfies ContextType} />
    </>
  );
}
