import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";

type AssignedToType = {
  userName: string;
  userId: string;
  email: string;
};

type ConsultantData = {
  consultant: { userName: string; email: string };
  leadId: string;
};

type RecommendationData = {
  recommendations: Array<{ country: string; pathway: string }>;
  leadId: string;
};
// Get all Migrations
const getMigrations = async (page: number, searchTerm?: string, status?: string) => {
  const query = buildQuery({
    page,
    searchTerm,
    status,
    limit: 30,
    sortBy: "-createdAt",
  });
  const res = await api.get(`migration/v1/leads/all?${query}`);
  return res.data.data;
};
export const useFetchMigrations = (page: number, searchTerm?: string, status?: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["migrations", page, searchTerm, status],
    () => getMigrations(page, searchTerm, status),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const getMigration = async (id: string) => {
  const res = await api.get(`migration/v1/leads/${id}`);
  return res.data.data;
};
export const useFetchMigration = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["migration", id],
    () => getMigration(id),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const getComments = async (id: string) => {
  const res = await api.get(`migration/v1/leads/${id}/comment`);
  return res.data.data;
};
export const useFetchComments = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["comments", id],
    () => getComments(id),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Action handlers
export const useMigrationActions = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    ({
      leadId,
      ...rest
    }: {
      assignType: string;
      assignedTo: AssignedToType;
      leadId: string;
    }) => {
      return api.post(`migration/v1/leads/${leadId}/assign`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Migration lead status assigned");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );
  const {
    mutate: mutateStatus,
    isLoading: isLoadingStatus,
    isError: isErrorStatus,
    error: errorStatus,
  } = useMutation(
    ({ status, leadId }: { status: string; leadId: string }) => {
      return api.patch(`migration/v1/leads/${leadId}`, { status });
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Migration lead status successfully updated");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateConsultant,
    isLoading: isLoadingConsultant,
    isError: isErrorConsultant,
    error: errorConsultant,
  } = useMutation(
    ({ leadId, ...rest }: ConsultantData) => {
      return api.post(`migration/v1/leads/${leadId}/set-consultant`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Consultant successfully set");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateRecommendation,
    isLoading: isLoadingRecommendation,
    isError: isErrorRecommendation,
    error: errorRecommendation,
  } = useMutation(
    ({ leadId, ...rest }: RecommendationData) => {
      return api.post(`migration/v1/leads/${leadId}/addRecommendations`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Recommendations added successfully");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateComment,
    isLoading: isLoadingComment,
    isError: isErrorComment,
    error: errorComment,
    isSuccess,
  } = useMutation(
    ({ leadId, ...message }: { leadId: string; message: string }) => {
      return api.post(`migration/v1/leads/${leadId}/comment`, message);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Comment added successfully");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateTag,
    isLoading: isLoadingTag,
    isError: isErrorTag,
    error: errorTag,
  } = useMutation(
    ({ leadId, ...rest }: { leadId: string; tags: Array<string> }) => {
      return api.post(`migration/v1/leads/${leadId}/add-tag`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Tags added successfully");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const handleAssign = (data: {
    assignType: string;
    assignedTo: AssignedToType;
    leadId: string;
  }) => {
    mutate(data);
  };
  const handleStatus = (data: { status: string }) => {
    mutateStatus(data as { status: string; leadId: string });
  };

  const handleConsultant = (data: ConsultantData) => {
    mutateConsultant(data);
  };

  const handleRecommendation = (data: RecommendationData) => {
    mutateRecommendation(data);
  };

  const handleComment = (data: { message: string; leadId: string }) => {
    mutateComment(data);
  };

  const handleTag = (data: { tags: Array<string>; leadId: string }) => {
    mutateTag(data);
  };

  return {
    handleAssign,
    handleStatus,
    handleConsultant,
    handleRecommendation,
    handleComment,
    handleTag,
    loading:
      isLoading ||
      isLoadingStatus ||
      isLoadingConsultant ||
      isLoadingRecommendation ||
      isLoadingComment ||
      isLoadingTag,
    isError:
      isError ||
      isErrorStatus ||
      isErrorConsultant ||
      isErrorRecommendation ||
      isErrorComment ||
      isErrorTag,
    error:
      error ||
      errorStatus ||
      errorConsultant ||
      errorRecommendation ||
      errorComment ||
      errorTag,
    isSuccess,
  };
};
