import back from "assets/back.svg";
import edit from "assets/edit.svg";
import Button from "components/Button";
import Status from "components/Status";
import Tier from "components/Tier";
import Document from "components/Document";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { convertDate } from "utils/fx";
import { AnyObject } from "utils/types";
import { useKYCHandlers } from "./useKycHandlers";
import Modal from "components/Modal";
import ApproveKYC from "./modals/ApproveKYC";
import RejectKYC from "./modals/RejectKYC";
import { ViewDocument } from "components/ViewDocument";
import UpdateName from "./modals/UpdateName";

type ModalType = "approve" | "reject" | "update" | undefined;
function PersonalKYCDetails({ data }: { data: AnyObject }) {
  const navigate = useNavigate();

  const { handleRetriggerKYC, loading, error, isError } = useKYCHandlers();

  const [modal, setModal] = useState<ModalType>(undefined);
  const [imageToShow, setImageToShow] = useState("");
  const [type, setType] = useState("");

  const showDocument = (image: string, type: string) => {
    setImageToShow(image);
    setType(type);
  };

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const onClose = () => setModal(undefined);

  return (
    <>
      <div
        className="pt-2 mb-4 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate(-1)}
      >
        <img src={back} alt="back" />
        KYC
      </div>
      {/*  */}

      {/* Top */}
      <div className="grid grid-cols-[1fr_120px_120px_120px] gap-4 items-center">
        <Status
          status={data?.status?.toLowerCase() ?? "approved"}
          width="w-32 mb-4"
        />
        {data?.status?.toLowerCase() === "pending" ? (
          <>
            <Button
              variant="primary"
              label="Approve"
              onClick={() => setModal("approve")}
            />
            <Button
              variant="danger"
              label="Reject"
              onClick={() => setModal("reject")}
            />
          </>
        ) : (
          <>
            <div />
            <div />
          </>
        )}

        {data?.status?.toLowerCase() === "failed" &&
          data?.idType !== "proofOfAddress" && (
            <Button
              variant="secondary"
              label="Re-trigger"
              loading={loading}
              onClick={() => handleRetriggerKYC({ requestId: data.id })}
            />
          )}
      </div>

      {/* Card */}
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <div className="border-b border-grey pb-3 mb-4">
          <p className="text-blackDark text-base font-bold">KYC Details</p>
        </div>

        <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3"> Account Name</p>
            <div className="flex items-center gap-0.5">
              <p
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/users/${data?.user?.id}`);
                }}
                className="text-blackDark text-sm font-medium underline"
              >
                {data?.user?.firstName} {data?.user?.middleName}{" "}
                {data?.user?.lastName}{" "}
              </p>
              {data?.currentAccountTier === 0 &&
                data?.status?.toLowerCase() === "failed" && (
                  <img
                    className="cursor-pointer"
                    src={edit}
                    alt="Edit"
                    onClick={() => setModal("update")}
                  />
                )}
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3"> ID Name</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.firstName} {data?.middleName} {data?.lastName}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Email</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.user?.email || "N/A"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Phone Number</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.user?.dialCode}
              {data?.user?.phoneNumber}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Type</p>
            <p className="text-sm text-blackDark font-medium cursor-pointer">
              {capitalize(data?.userType)}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Current Tier</p>
            <Tier status={data?.currentAccountTier} />
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Requested Tier</p>
            <Tier status={data?.requestedTier} />
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Date Opened</p>
            <p className="text-sm  font-medium cursor-pointer">
              {convertDate(data?.createdAt)}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">ID Type</p>
            <p className="text-sm font-medium cursor-pointer">
              {capitalize(data?.idType)}
            </p>
          </div>

          {data?.idNumber && (
            <div>
              <p className="text-coolGrey text-sm mb-3">ID Number</p>
              <p className="text-sm font-medium cursor-pointer">
                {data?.idNumber}
              </p>
            </div>
          )}

          {data?.address && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Address</p>
              <p className="text-sm font-medium cursor-pointer">
              {data?.address?.street} {data?.address?.street1} {data?.address?.street2}{" "}
                {data?.address?.city} {data?.address?.state},{" "}
                {data?.address?.postalCode} {data?.address?.country}
              </p>
            </div>
          )}

          <div>
            <p className="text-coolGrey text-sm mb-3">Status Message</p>
            <p className="text-sm font-medium cursor-pointer">
              {data?.statusMessage}
            </p>
          </div>

          {data?.addressRejectionReason && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Status Message</p>
              <p className="text-sm font-medium cursor-pointer">
                {data?.statusMessage}
              </p>
            </div>
          )}

          {data?.photo && (
            <ViewDocument
              file={data?.photo}
              name="ID"
              showDocument={() => showDocument(data?.photo, "ID")}
            />
          )}

          {data?.userImage && (
            <ViewDocument
              file={data?.userImage}
              name="Selfie Image"
              showDocument={() => showDocument(data?.userImage, "Selfie Image")}
            />
          )}

          {data?.proofOfAddress && (
            <ViewDocument
              file={data?.proofOfAddress}
              name="Proof of Address"
              showDocument={() =>
                showDocument(data?.proofOfAddress, "Proof of Address")
              }
            />
          )}
        </div>
      </div>
      <Modal
        isShown={
          !!modal || ["Selfie Image", "ID", "Proof of Address"].includes(type)
        }
      >
        {modal === "approve" && <ApproveKYC onClose={onClose} data={data} />}
        {modal === "reject" && <RejectKYC onClose={onClose} data={data} />}
        {modal === "update" && <UpdateName onClose={onClose} data={data} />}
        {["Selfie Image", "ID", "Proof of Address"].includes(type) && (
          <Document
            onClose={() => {
              setImageToShow("");
              setType("");
            }}
            image={imageToShow}
            type={type}
          />
        )}
      </Modal>
    </>
  );
}

export default PersonalKYCDetails;
