import Loader from "components/Loader";
import { useDocumentTitle } from "hooks/useDocument";
import { useNavigate, useParams } from "react-router-dom";
import {
} from "./useUsersHandlers";
import back from "assets/back.svg";
import { AnyObject } from "yup";
import EmptyTable from "components/EmptyTable";
import AccessDenied from "components/AccessDenied";


import {  formatNumber } from "utils/fx";
import { startCase } from "lodash";
import Status from "components/Status";
import { useFetchAccount } from "../accounts/useAccountsHandlers";
import Tier from "components/Tier";
import SubAccountTransactions from "./SubAccountTransactions";

export default function SubAccountDetails() {
  useDocumentTitle("Sub Account Details");

  const navigate = useNavigate();

  const {walletId} = useParams();

  const { data, isLoading, error } = useFetchAccount(walletId ?? "");


  if (isLoading) return <Loader />;

  if (data?.results?.length === 0) {
    return <EmptyTable />;
  }

  if (((error) as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  return (
    <>
    <div
        className="pt-2 mb-4 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate(-1)}
      >
        <img src={back} alt="back" />
        Sub Accounts
      </div>
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <div className="border-b border-grey pb-3 mb-4">
          <p className="text-blackDark text-[15px] font-bold flex items-center gap-3">
            Account Inflow: <span className="text-primary font-normal text-lg">{formatNumber(data?.availableBalance, data?.currency)}</span>
            
          </p>
        </div>

        <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">Account Name</p>
            <p className="text-blackDark text-sm font-medium">
              {startCase(data?.accountName)}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Number</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {data?.accountNumber}
              </p>
              
             
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Bank Name</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {data?.provider}
              </p>
              
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Type</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {startCase(data?.userType)}
              </p>
              
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Wallet Type</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {startCase(data?.walletType)}
              </p>
              
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Wallet SubType</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {startCase(data?.walletSubType)}
              </p>
              
            </div>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Account Status</p>
            <Status
              width="w-3/4"
              status={
                data?.pndActive ? "PND" : data?.active ? "active" : "inactive"
              }
            />
          </div>

          {data?.pndActiveReason && (
            <div>
              <p className="text-coolGrey text-sm mb-3">PND Reason</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.pndActiveReason}
              </p>
            </div>
          )}

          <div>
            <p className="text-coolGrey text-sm mb-3">Tier Status</p>
            <Tier status={data?.accountTier} />
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Ledger Balance</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {formatNumber(data?.ledgerBalance, data?.currency)}
              </p>
              
            </div>
          </div>

         
          <div>
            <p className="text-coolGrey text-sm mb-3">Total Daily Transactions</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {formatNumber(data?.totalDailyTransaction, data?.currency)}
              </p>
              
            </div>
          </div>

        </div>
      </div>

      <SubAccountTransactions />

    </>
  );
}
