import { useQuery } from "@tanstack/react-query";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";

export type DateFilter = {
  dateFrom?: string;
  dateTo?: string;
};

const getWalletAnalytics = async (dateFrom?: string, dateTo?: string) => {
  const query = buildQuery({
    dateFrom,
    dateTo,
  });
  const res = await api.get(`wallets/v1/analytics?${query}`);
  return res.data.data;
};
export const useFetchWalletAnalytics = ({ dateFrom, dateTo }: DateFilter) => {
  const { data, isLoading, isError, error } = useQuery(
    ["walletAnalytics", dateFrom, dateTo],
    () => getWalletAnalytics(dateFrom, dateTo),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const getUserAnalytics = async (dateFrom?: string, dateTo?: string) => {
  const query = buildQuery({
    dateFrom,
    dateTo,
  });
  const res = await api.get(`users/v1/analytics?${query}`);
  return res.data.data.analytics;
};
export const useFetchUserAnalytics = ({ dateFrom, dateTo }: DateFilter) => {
  const { data, isLoading, isError, error } = useQuery(
    ["userAnalytics", dateFrom, dateTo],
    () => getUserAnalytics(dateFrom, dateTo),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const getTransactionReport = async (dateFrom?: string, dateTo?: string) => {
  const query = buildQuery({
    dateFrom,
    dateTo,
  });
  const res = await api.get(`transactions/v1/report?${query}`);
  return res.data.data.response;
};
export const useGetTransactionReport = ({ dateFrom, dateTo }: DateFilter) => {
  const { data, isLoading, isError, error } = useQuery(
    ["transactionReport", dateFrom, dateTo],
    () => getTransactionReport(dateFrom, dateTo),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};