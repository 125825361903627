import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";

type UpdateKYCType = {
  firstName: string;
  middleName: string;
  lastName: string;
  kycId: string;
  userId: string;
};

// Get all KYCs
const getKYCs = async (
  page: number,
  type: "individual" | "business",
  filter?: string
) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    limit: 30,
    userType: type,
    sortBy: "createdAt:desc",
  });
  const res = await api.get(`users/v1/kyc/kyc-requests?${query}`);
  return res.data.data;
};
export const useFetchKYCs = (
  page: number,
  type: "individual" | "business",
  filter?: string
) => {
  const { data, isLoading, isError, error } = useQuery(
    ["kYCs", page, type, filter],
    () => getKYCs(page, type, filter),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get single KYC
const getKYC = async (id: string) => {
  const res = await api.get(`users/v1/kyc/kyc-request/${id}`);
  return res.data.data;
};
export const useFetchKYC = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["kYC", id],
    () => getKYC(id),
    { retry: false, refetchOnWindowFocus: false, enabled: !!id }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get Business
const getBusiness = async (id: string) => {
  const res = await api.get(`users/v1/business/${id}`);
  return res.data.data;
};
export const useGetBusiness = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["business", id],
    () => getBusiness(id),
    { retry: false, refetchOnWindowFocus: false, enabled: !!id }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Action handlers
export const useKYCHandlers = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    (data: { approved: boolean; requestId: string }) => {
      return api.post("users/v1/kyc/kyc-request/approve", data);
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["kYC", variables.requestId]);
        toast.success("KYC successfully approved!");
      },
    }
  );

  const {
    mutate: mutateReject,
    isLoading: isLoadingReject,
    isError: isErrorReject,
    error: errorReject,
  } = useMutation(
    (data: {
      approved: boolean;
      requestId: string;
      rejectionReason: string;
    }) => {
      return api.post("users/v1/kyc/kyc-request/approve", data);
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["kYC", variables.requestId]);
        toast.success("KYC rejected!");
      },
    }
  );

  const {
    mutate: mutateReTrigger,
    isLoading: isLoadingReTrigger,
    isError: isErrorReTrigger,
    error: errorReTrigger,
  } = useMutation(
    ({ requestId }: { requestId: string }) => {
      return api.post(`users/v1/kyc/kyc-request/${requestId}/re-trigger`, {});
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["kYC", variables.requestId]);
        toast.success("KYC re-triggered!");
      },
    }
  );

  const {
    mutate: mutateBusiness,
    isLoading: isLoadingBusiness,
    isError: isErrorBusiness,
    error: errorBusiness,
  } = useMutation(
    ({ verified, id }: { verified: boolean; id: string }) => {
      return api.patch(`users/v1/business/${id}`, { verified });
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["business", variables.id]);
        toast.success("Business successfully updated!");
      },
    }
  );

  const {
    mutate: mutateUpdateUser,
    isLoading: loadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
  } = useMutation(
    ({kycId, userId, ...rest}: UpdateKYCType) => {
      return api.patch(`users/v1/${userId}`, {
        ...rest,
      });
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["kYC", variables.kycId]);
        toast.success("User successfully updated!");
      },
    }
  );

  const handleApproveKYC = (data: { approved: boolean; requestId: string }) => {
    mutate(data);
  };

  const handleRejectKYC = (data: {
    approved: boolean;
    requestId: string;
    rejectionReason: string;
  }) => {
    mutateReject(data);
  };

  const handleRetriggerKYC = (data: { requestId: string }) => {
    mutateReTrigger(data);
  };

  const handleUpdateBusiness = (data: { verified: boolean; id: string }) => {
    mutateBusiness(data);
  };

  const handleUpdateUser = (data: UpdateKYCType) => {
    mutateUpdateUser(data);
  };
  return {
    handleApproveKYC,
    handleRejectKYC,
    handleRetriggerKYC,
    handleUpdateBusiness,
    handleUpdateUser,
    loading:
      isLoading ||
      isLoadingReject ||
      isLoadingReTrigger ||
      isLoadingBusiness ||
      loadingUpdate,
    isError:
      isError ||
      isErrorReject ||
      isErrorReTrigger ||
      isErrorBusiness ||
      isErrorUpdate,
    error:
      error || errorReject || errorReTrigger || errorBusiness || errorUpdate,
  };
};
