import { ChangeEvent } from "react";

interface TextAreaProps {
  id: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: "true" | "false";
  errorMessage?: string;
  searchInput?: boolean;
  value: string;
  name: string;
  single?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({
  id,
  label,
  disabled,
  placeholder = "",
  required = false,
  error = "false",
  errorMessage,
  value,
  onChange,
  ...rest
}: TextAreaProps) => {
  return (
    <div className={`w-full relative mb-6`}>
      {label && (
        <label htmlFor={id} className={`text-sm text-blackDark font-medium`}>
          {label}
        </label>
      )}
      <textarea
        id={id}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        data-error={error}
        value={value}
        onChange={onChange}
        rows={3}
        cols={3}
        {...rest}
        className="w-full p-[10px] font-light text-sm mt-2 bg-white border rounded outline-none transition disabled:opacity-70 disabled:cursor-not-allowed pl-4 border-grey focus:border-primary"
      />

      {error === "true" && (
        <small className="text-danger relative">{errorMessage}</small>
      )}
    </div>
  );
};

export default TextArea;
