import { ChangeEvent } from "react";

interface SearchProps {
  id: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: "true" | "false";
  errorMessage?: string;
  value: string;
  name: string;
  data: Array<{ value: string; label: string }>;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const Select = ({
  id,
  label,
  type = "text",
  disabled,
  placeholder = "",
  required = false,
  error = "false",
  errorMessage,
  value,
  data,
  onChange,
  ...rest
}: SearchProps) => {
  return (
    <div className={`w-full relative mb-4`}>
      {label && (
        <label htmlFor={id} className={`text-md text-sm font-medium`}>
          {label}
        </label>
      )}
      <select
        id={id}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        data-error={error}
        value={value}
        onChange={onChange}
        {...rest}
        className="w-full p-[10px] h-10 text-sm font-light mt-2 bg-white border rounded outline-none transition disabled:opacity-70 disabled:cursor-not-allowed pl-4 border-grey focus:border-primary"
      >
        <option value={-1}>{placeholder}</option>
        {data?.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>

      {error === "true" && (
        <small className="text-danger relative">{errorMessage}</small>
      )}
    </div>
  );
};

export default Select;
