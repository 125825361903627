import Loader from "components/Loader";
import { useDocumentTitle } from "hooks/useDocument";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useFetchUserSubAccounts,
  useFetchUserSubAccountSummary,
} from "./useUsersHandlers";
import { useDebounce } from "hooks/useDebounce";
import { AnyObject } from "yup";
import EmptyTable from "components/EmptyTable";
import AccessDenied from "components/AccessDenied";
import Input from "components/inputs/Input";
import Button from "components/Button";
import filter from "assets/filter.svg";
import userGroup from "assets/user-group.svg";
import multiUser from "assets/multi-user.svg";
import dollar from "assets/green-dollar.svg";

import { convertDate, convertTime, formatNumber } from "utils/fx";
import Pagination from "components/Pagination";
import { startCase } from "lodash";
import Status from "components/Status";
import CsvButton from "components/CsvButton";

export default function SubAccount() {
  useDocumentTitle("Sub Account");

  const navigate = useNavigate();

  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { data, error, isLoading } = useFetchUserSubAccounts(
    id ?? "",
    page,
    useDebounce(searchTerm)
  );

  const {
    data: summary,
    error: errorSummary,
    isLoading: isLoadingSummary,
  } = useFetchUserSubAccountSummary(id ?? "");

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  if (isLoading || isLoadingSummary) return <Loader />;

  if (data?.wallets?.length === 0) {
    return <EmptyTable />;
  }

  if (((error || errorSummary) as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  const csvData = [["Status", "Name", "Wallet Subtype", "User Type", "Date Created"]];

  const getCSVData = () => {
    if (data?.wallets?.length === 0) {
      return [];
    }
    
    for (let i = 0; i < data?.wallets?.length; i++) {
      let arr = [];
      arr.push(data?.wallets[i].active ? 'active' : 'inactive');
      arr.push(
        `${data?.wallets[i]?.accountAlias}`
      );
      arr.push(data?.wallets[i]?.walletSubType);
      arr.push(
        `${data?.wallets[i]?.userType}`
      );
      arr.push(convertDate(new Date(data?.wallets[i]?.createdAt)));

      csvData.push(arr);
    }
    return csvData;
  };

  return (
    <>
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">Sub Accounts</p>
        <div className="flex justify-between items-center p-10 mx-40">
          <div className="flex items-center gap-2 w-20">
            <img src={userGroup} alt="user group" />
            <div>
              <p className="text-blackDark font-semibold text-lg text-center">
                {summary?.subAccounts ?? 0}
              </p>
              <p className="text-blackDark text-center">Total Accounts</p>
            </div>
          </div>

          <div className="flex items-center gap-2 w-20">
            <img src={multiUser} alt="multi user" />
            <div>
              <p className="text-blackDark font-semibold text-lg text-center">
              {summary?.activeSubAccounts ?? 0}
              </p>
              <p className="text-blackDark text-center">Active Accounts</p>
            </div>
          </div>

          <div className="flex items-center gap-2 w-20">
            <img src={dollar} alt="dollar" />
            <div>
              <p className="text-blackDark font-semibold text-lg text-center">
                {formatNumber(summary?.sumCredit ?? 0, "NGN")}
              </p>
              <p className="text-blackDark text-center">Total Inflow</p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div className="flex gap-2 items-center mt-2">
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter filter"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          <div className="flex gap-4 justify-end ml-36">
            {/* <Button size="medium" label="Create User" /> */}
            <CsvButton
              data={getCSVData()}
              filename="Sub-Account.csv"
            />
          </div>
        </div>
      </div>
      <div className="py-6 px-8 bg-white border border-btnInactive gap-4 grid grid-cols-5 items-center">
        <p className="text-sm font-bold text-blackDark">Status</p>
        <p className="text-sm font-bold text-blackDark">Name</p>
        <p className="text-sm font-bold text-blackDark">Wallet Subtype</p>
        <p className="text-sm font-bold text-blackDark">User Type</p>
        <p className="text-sm font-bold text-blackDark">Date Created</p>
      </div>

      {data?.wallets?.map((result: AnyObject) => (
        <Link
          to={`/users/${id}/sub-account/${result.accountNumber}/wallet/${result.walletId}`}
          key={result.walletId}
          className="py-2 px-8 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-5 items-center"
        >
          <div
            className="cursor-pointer flex gap-0.5 items-baseline"
            onClick={(e) => {
              e.preventDefault();
              if (result.author) {
                navigate(`/users/${result.author.id}`);
              }
            }}
          >
            <Status status={result.active ? "active" : "inactive"} />
          </div>
          <p className="text-sm text-blackDark">
            {startCase(result.accountAlias)}
          </p>

          <p className="text-sm text-blackDark">
            {startCase(result.walletSubType)}
          </p>

          <p className="text-sm text-blackDark">
            {startCase(result.userType)}{" "}
          </p>

          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.createdAt))} <br />{" "}
            {convertTime(new Date(result.createdAt))}
          </p>
        </Link>
      )) || <EmptyTable />}

      {data?.wallets?.length > 0 && (
        <Pagination data={data} prev={prev} next={next} />
      )}
    </>
  );
}
