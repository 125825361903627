import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";
import { ExchangeValues } from "./modal/CreateExchange";
import { EditExchangeValues } from "./modal/EditExchange";

// Get all Cards
const getExchanges = async (page: number, filter?: string) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    pageSize: 30,
    sortBy: "-createdAt",
  });
  const res = await api.get(`wallets/v1/exchange-rate/all?${query}`);
  return res.data.data;
};
export const useFetchExchanges = (page: number, filter?: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["exchanges", page, filter],
    () => getExchanges(page, filter),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Action handlers
export const useFxActions = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    (data: ExchangeValues) => {
      return api.post("wallets/v1/exchange-rate", data);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Exchange successfully created");
        queryClient.invalidateQueries(["exchanges", 1]);
      },
      onError: async (error: string) => {
        toast.error(error);
      },
    }
  );

  const {
    mutate: mutateEdit,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
  } = useMutation(
    ({ fromCurrency, toCurrency, rate }: EditExchangeValues) => {
      return api.put(
        `wallets/v1/exchange-rate?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}`,
        { rate }
      );
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Exchange successfully updated");
        queryClient.invalidateQueries(["exchanges", 1]);
      },
      onError: async (error: string) => {
        toast.error(error);
      },
    }
  );

  const handleCreateExchange = (data: ExchangeValues) => {
    mutate(data as ExchangeValues);
  };

  const handleUpdateExchange = (data: EditExchangeValues) => {
    mutateEdit(data as EditExchangeValues);
  };
  return {
    handleCreateExchange,
    handleUpdateExchange,
    loading: isLoading || isLoadingUpdate,
    isError: isError || isErrorUpdate,
    error: error || errorUpdate,
  };
};
