import Button from "components/Button";
import { AnyObject, object, string } from "yup";
import close from "assets/close.svg";
import TextArea from "components/inputs/TextArea";
import { useFormik } from "formik";
import { useAccountActions } from "../useAccountsHandlers";
import { ChangeEvent, useRef, useState } from "react";
import { api } from "utils/api";
import { AxiosProgressEvent } from "axios";
import toast from "react-hot-toast";

interface ActivateProps {
  onClose: () => void;
  data: AnyObject;
}

const ActivateAccount = ({ onClose, data }: ActivateProps) => {

  const { handleActivateAccount, loading } = useAccountActions(onClose);

  const inputIdFileRef = useRef<HTMLInputElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [progress, setProgress] = useState(0);
  const [preview, setPreview] = useState<string>("");
  const [previewUtility, setPreviewUtility] = useState<string>("");

  const [attachments, setAttachments] = useState<string[] | []>([]);
  const [type, setType] = useState<"id" | "utility" | undefined>();

  const openFileExplorerId = () => {
    if (progress > 0 && progress < 100) return;
    setType(undefined);
    if (inputIdFileRef.current) {
      inputIdFileRef.current.click();
    }
  };
  const openFileExplorer = () => {
    if (progress > 0 && progress < 100) return;
    setType(undefined);
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    setType("id");
    let reader = new FileReader();
    let file = e.target.files?.[0];
    reader.onload = () => {
      setPreview(reader.result as string);
    };
    postImage(file as File);

    return reader.readAsDataURL(file as File);
  };

  const handleImageUtility = (e: ChangeEvent<HTMLInputElement>) => {
    setType("utility");
    let reader = new FileReader();
    let file = e.target.files?.[0];
    reader.onload = () => {
      setPreviewUtility(reader.result as string);
    };
    postImage(file as File);
    return reader.readAsDataURL(file as File);
  };

  const postImage = async (file: File) => {
    setProgress(0);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
          );
          setProgress(percentCompleted);
        },
      };
      const uploadData = new FormData();
      uploadData.append("file", file);
      const res = await api.post("media/v1/upload/pnd", uploadData, config);
      setAttachments((prev) => [...prev, res.data.data.media[0].path.download]);
    } catch (error: any) {
      toast.error(error.message ?? "An error has occurred while uploading");
    }
  };
  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<{ reason: string; pndStatus: boolean; attachments: string[] }>({
      initialValues: {
        reason: "",
        pndStatus: false,
        attachments: [],
      },
      validationSchema: object({
        reason: string().required("A reason must be provided"),
      }),
      onSubmit: (values) => {
        values.attachments = attachments;
        handleActivateAccount(values, data.accountNumber);
      },
    });

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[400px] m-auto mt-16 overflow-auto h-[550px] rounded"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-2">
        <p className="text-black text-base font-bold">Activate Account</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b border-btnInactive">
        <TextArea
          id="reason"
          label="Reason for Activation"
          name="reason"
          value={values.reason}
          onChange={handleChange}
          error={`${!!errors["reason"] && !!touched["reason"]}`}
          errorMessage={errors["reason"]}
          onBlur={handleBlur}
        />
        <div className={`w-full relative mb-4`}>
          <label
            htmlFor={"id"}
            className={`text-xs text-blackDark font-medium`}
          >
            Upload ID (NIN, PASSPORT, DRIVER'S LICENSE)
          </label>
          <div
            onClick={openFileExplorerId}
            className="border border-dashed border-btnInactive p-2 rounded cursor-pointer"
          >
            {preview ? (
              <div className="flex justify-between items-center">
                <img className="w-20 h-12 cover" src={preview} alt="" />

                {type === "id" && progress > 0 && progress < 100 && (
                  <div className="w-full h-2 bg- rounded border border-greenAccent">
                    <div
                      className="bg-primary h-2 rounded w-50"
                      style={{ width: `${progress}%` }}
                    />
                    <span className="text-xs">
                      {progress < 100 ? "uploading..." : "uploaded"} {progress}%
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <p className="text-center text-sm text-coolGrey">
                + Choose a file
              </p>
            )}
            <input
              id="id"
              type="file"
              name="id"
              hidden
              accept=".pdf, .png, .jpeg, .pg"
              ref={inputIdFileRef}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleImage(e)}
            />
          </div>
          <p className="text-xs text-coolGrey mt-1">
            Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.
          </p>
        </div>

        <div className={`w-full relative mb-4`}>
          <label
            htmlFor={"id"}
            className={`text-xs text-blackDark font-medium`}
          >
            Upload Electricity Bill
          </label>
          <div
            onClick={openFileExplorer}
            className="border border-dashed border-btnInactive p-2 rounded cursor-pointer"
          >
            {previewUtility ? (
              <div className="flex justify-between items-center">
                <img className="w-20 h-12 cover" src={previewUtility} alt="" />
                {type === "utility" && progress > 0 && progress < 100 && (
                  <div className="w-full h-2 bg- rounded border border-greenAccent">
                    <div
                      className="bg-primary h-2 rounded w-50"
                      style={{ width: `${progress}%` }}
                    />
                    <span className="text-xs">
                      {progress < 100 ? "uploading..." : "uploaded"} {progress}%
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <p className="text-center text-sm text-coolGrey">
                + Choose a file
              </p>
            )}
            <input
              id="utility"
              type="file"
              name="utility"
              hidden
              accept=".pdf, .png, .jpeg, .pg"
              ref={inputFileRef}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleImageUtility(e)
              }
            />
          </div>
          <p className="text-xs text-coolGrey mt-1">
            Supported file types: PDF, PNG & JPEG. Max file size allowed is 3MB.
          </p>
        </div>
      </div>

      <div className="p-2 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Activate" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default ActivateAccount;
