import { useNavigate, useParams } from "react-router-dom";
import back from "assets/back.svg";
import caretRight from "assets/caret-right-red.svg";
import { useFetchAccount } from "./useAccountsHandlers";
import Loader from "components/Loader";
import { AnyObject } from "utils/types";
import AccessDenied from "components/AccessDenied";
import Status from "components/Status";
import { startCase } from "lodash";
import Tier from "components/Tier";
import { formatNumber } from "utils/fx";
import AccountTransactions from "./AccountTransactions";
import Modal from "components/Modal";
import { useEffect, useState } from "react";
import ActivateAccount from "./modals/Activate";
import { useAuthContext } from "hooks/useAuthContext";

export default function Account() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const {user } = useAuthContext()


  const canViewAccounts = user?.permissions?.includes('view:accounts')
 

  const { data, isLoading: loading, error } = useFetchAccount(id ?? "");

  useEffect(() => {
    if(!canViewAccounts && !loading) {
      navigate('/home')
    }
  }, [canViewAccounts, loading, navigate])

  if (loading) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }
  return (
    <>
      <div
        className="pt-2 mb-4 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate(-1)}
      >
        <img src={back} alt="back" />
        Accounts
      </div>
      {/*  */}
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <div className="border-b border-grey pb-3 mb-4 flex items-center gap-6">
          <p className="text-blackDark text-base font-bold">
            Account Balance:{" "}
            <span className="text-primary text-lg font-medium">
              {formatNumber(data.availableBalance, data.currency ?? "NGN")}
            </span>
          </p>
          {data.pndActive && (
            <span
              role="button"
              onClick={() => setShow(true)}
              className="bg-[#ffeded] w-16 cursor-pointer rounded text-danger text-sm inline-flex p-1 items-center justify-center gap-1"
            >
              PND <img src={caretRight} alt="" />
            </span>
          )}
        </div>

        <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">Account Name</p>
            <p className="text-blackDark text-sm font-medium">
              {data.accountName}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Number</p>
            <p className="text-blackDark text-sm font-medium">
              {data.accountNumber}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Type</p>
            <p className="text-blackDark text-sm font-medium">
              {startCase(data.userType)}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Username</p>
            <p className="text-blackDark text-sm font-medium">
              {data.userName}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Wallet Type</p>
            <p className="text-blackDark text-sm font-medium">
              {startCase(data.walletType)}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Wallet Subtype</p>
            <p className="text-blackDark text-sm font-medium">
              {startCase(data.walletSubType)}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Provider</p>
            <p className="text-blackDark text-sm font-medium">
              {data.provider}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Tier Status</p>
            <Tier status={data.accountTier} />
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Status</p>
            <Status
              width="w-3/4"
              status={
                data.pndActive ? "PND" : data.active ? "active" : "inactive"
              }
            />
          </div>

          {data.pndActiveReason && (
            <div>
              <p className="text-coolGrey text-sm mb-3">PND Reason</p>
              <p className="text-blackDark text-sm font-medium">
                {data.pndActiveReason}
              </p>
            </div>
          )}
        </div>
        <Modal isShown={show}>
          <ActivateAccount onClose={() => setShow(false)} data={data} />
        </Modal>
      </div>

      {/* Wallet Transactions */}
      <>
        <p className="py-2 text-greyDark font-bold"> Transactions</p>
        <AccountTransactions userId={data.userId} />
      </>
    </>
  );
}
