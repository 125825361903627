import medalZero from "assets/medal-0.svg";
import medal from "assets/medal.svg";
import medalTwo from "assets/medal-2.svg";
import medalThree from "assets/medal-3.svg";

export type TierType = "0" | "1" | "2" | "3";

const tierStatus: Record<TierType, JSX.Element> = {
  "0": <><img src={medalZero} alt="medal" /> Tier 0</>,
  "1": (
    <>
      <img src={medal} alt="medal" /> Tier 1
    </>
  ),
  "2": (
    <>
      <img src={medalTwo} alt="medal" /> Tier 2
    </>
  ),
  "3": (
    <>
      <img src={medalThree} alt="medal" /> Tier 3
    </>
  ),
};

const Tier = ({ status }: { status: TierType }) => {
  return (
    <span className="text-blackDark text-sm font-medium inline-flex">
      {tierStatus[status] ?? "N/A"}
    </span>
  );
};

export default Tier;
