import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import back from "assets/back.svg";
import { useFetchUser, useFetchUserKYC } from "./useUsersHandlers";
import Loader from "components/Loader";
import { AnyObject, ContextType } from "utils/types";
import AccessDenied from "components/AccessDenied";
import Status from "components/Status";
import { getUserStatus } from "./Users";
import Tier from "components/Tier";
import { convertDate, getKYCObject } from "utils/fx";
import { useEffect, useState } from "react";
import check from "assets/check.svg";
import edit from "assets/edit.svg";
import Modal from "components/Modal";
import EditUser from "./modals/EditUser";

const tabs = ["transactions", "accounts", "cards", "settings", "sub-account"];

type tabType = (typeof tabs)[number];

export default function User() {
  const param = useParams();
  const navigate = useNavigate();

  const { data, error, isLoading } = useFetchUser(param.id ?? "");

  const { data: kyc, isLoading: loading } = useFetchUserKYC(param.id ?? "");

  const [tab, setTab] = useState<tabType>("transactions");
  const [type, setType] = useState<"phoneNumber" | "dateOfBirth" | "">("");

  useEffect(() => {
    if (param["*"]) {
      if (tabs.includes(param["*"].split("/")[2])) {
        setTab(param["*"].split("/")[2]);
      } else {
        setTab("transactions");
      }
    }
  }, [param]);

  if (isLoading || loading) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  return (
    <>
      <div
        className="pt-2 mb-4 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate(-1)}
      >
        <img src={back} alt="back" />
        Users
      </div>
      {/*  */}
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <div className="border-b border-grey pb-3 mb-4">
          <p className="text-blackDark text-[15px] font-bold flex items-center gap-3">
            Account Locked
            {data?.user?.accountLocked ? (
              <span className=" w-[15px] h-[15px] inline-flex bg-[#20D4A5] rounded items-center justify-center">
                <img src={check} alt="check" className="w-2.5 h-2.5" />
              </span>
            ) : (
              <span className="font-normal">No</span>
            )}
          </p>
        </div>

        <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">User Name</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.user?.firstName} {data?.user?.lastName}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Phone Number</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {data?.user?.dialCode}
                {data?.user?.phoneNumber}
              </p>
              <Status
                status={data?.user?.isPhoneVerified ? "verified" : "pending"}
              />
              {!data?.user?.isPhoneVerified && (
                <img
                  className="cursor-pointer"
                  src={edit}
                  alt="Edit"
                  onClick={() => setType("phoneNumber")}
                />
              )}
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Email</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {data?.user?.email}
              </p>
              <Status
                status={data?.user?.isEmailVerified ? "verified" : "pending"}
              />
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">BVN</p>
            <div className="flex gap-3 items-center">
              <p className="text-blackDark text-sm font-medium">
                {data?.user?.bvn}
              </p>
              <Status
                status={data?.user?.bvnValidated ? "verified" : "pending"}
              />
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Tier</p>
            <Tier status={kyc?.accountTier} />
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Account Status</p>
            <Status status={getUserStatus(data?.user)} width="w-3/4" />
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Date of Birth</p>
            <div className="flex items-center gap-0.5">
              <p className="text-blackDark text-sm font-medium">
                {data?.user.dateOfBirth ? convertDate(data?.user.dateOfBirth) : "N/A"}
              </p>
              <img
                className="cursor-pointer"
                src={edit}
                alt="Edit"
                onClick={() => setType("dateOfBirth")}
              />
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Date Created</p>
            <p className="text-blackDark text-sm font-medium">
              {convertDate(data?.user.createdAt)}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">2FA Enabled</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.user?.allow2FA ? "Enabled" : "Disabled"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">KYC Status</p>
            <div className="flex gap-3 items-center">
              {getKYCObject(kyc)?.status ? (
                <>
                  <p
                    role="button"
                    onClick={() => navigate(`/kyc/${kyc._id}`)}
                    className="text-primary text-sm font-medium"
                  >
                    View KYC
                  </p>
                  <Status
                    status={getKYCObject(kyc)?.status?.toLowerCase()}
                    width="w-3/5"
                  />
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Tabs */}
      {data?.user?.userType === "individual" ? (
        <div className="pb-4 flex justify-start items-center gap-8 ml-16 font-medium">
          <Link
            to=""
            role="tab"
            className={`pb-1 cursor-pointer ${
              tab === "transactions"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            }`}
          >
            Transactions
          </Link>
          <Link
            to="accounts"
            role="tab"
            className={`pb-1 cursor-pointer ${
              tab === "accounts"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            }`}
          >
            Accounts
          </Link>
          <Link
            to="cards"
            role="tab"
            className={`${
              tab === "cards"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            } pb-1 cursor-pointer`}
          >
            Cards
          </Link>
          <Link
            to="settings"
            role="tab"
            className={`${
              tab === "settings"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            } pb-1 cursor-pointer`}
          >
            Settings
          </Link>
        </div>
      ) : (
        <div className="pb-4 flex justify-start items-center gap-8 ml-16 font-medium">
          <Link
            to=""
            role="tab"
            className={`pb-1 cursor-pointer ${
              tab === "transactions"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            }`}
          >
            Transactions
          </Link>
          <Link
            to="settings"
            role="tab"
            className={`${
              tab === "settings"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            } pb-1 cursor-pointer`}
          >
            Settings
          </Link>
          <Link
            to="sub-account"
            role="tab"
            className={`${
              tab === "sub-account"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            } pb-1 cursor-pointer`}
          >
            Sub-account
          </Link>
        </div>
      )}
      {/*  */}
      <Outlet
        context={
          {
            data: { ...data, ...kyc },
            next: () => {},
            prev: () => {},
          } satisfies ContextType
        }
      />

      <Modal isShown={["phoneNumber", "dateOfBirth"].includes(type)}>
        <EditUser
          user={data?.user as AnyObject}
          type={type as "dateOfBirth" | "phoneNumber"}
          onClose={() => setType("")}
        />
      </Modal>
    </>
  );
}
