import card from "assets/card.svg";
import group from "assets/group.svg";
import money from "assets/money-deposit.svg";
import CardItem from "components/CardItem";
import Loader from "components/Loader";
import { abbreviateNumber } from "utils/fx";
import { AnyObject } from "utils/types";
import AllTransactions from "./AllTransactions";
import DeviceType from "./DeviceType";
import MostUsed from "./MostUsed";
import StatusReport from "./StatusReport";
import TransactionHistory from "./TransactionHistory";
import {
  useFetchUserAnalytics,
  useFetchWalletAnalytics,
} from "./useHomeHandlers";
import { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { useFetchTransactions } from "../transactions/useTransactionHandlers";

type DateValueType = any;
export default function Home() {
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });

  const { data, isLoading } = useFetchWalletAnalytics({
    dateFrom: value.startDate,
    dateTo: value.endDate,
  });

  const { data: userAnalytics, isLoading: loadingUserAnalytics } =
    useFetchUserAnalytics({
      dateFrom: value.startDate,
      dateTo: value.endDate,
    });

  
    const { data: transHistory, loading } = useFetchTransactions(
      "ALL",
      1,
    );

  if (isLoading || loadingUserAnalytics || loading) {
    return <Loader />;
  }

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
  };
  return (
    <>
      <Datepicker
        value={value}
        onChange={(val: DateValueType) => handleValueChange(val)}
        showShortcuts
        separator="to"
        containerClassName="flex justify-end cursor-pointer mb-6 relative"
        inputClassName="p-2 rounded w-[30%] bg-white border border-grey"
        primaryColor="green"
        maxDate={new Date()}
      />

      <div className="grid grid-cols-[2fr_1fr]">
        <div className="flex flex-col gap-y-8">
          <div className="flex flex-wrap gap-6">
            <CardItem
              title="Registered Users"
              cardIcon={group}
              value={
                abbreviateNumber(
                  userAnalytics?.totalRegisteredUsers,
                  0
                ) as string
              }
            />
            {data?.totalInflow?.map((inflow: AnyObject) => {
              return (
                <CardItem
                  key={inflow.currency}
                  title="Total Inflow"
                  cardIcon={money}
                  value={`${inflow.currency} ${abbreviateNumber(
                    inflow.amount,
                    1
                  )}`}
                />
              );
            })}
            {data?.totalTransactionValue?.map((inflow: AnyObject) => {
              return (
                <CardItem
                  key={inflow.currency}
                  title="Total Transaction Value"
                  cardIcon={money}
                  value={`${inflow.currency} ${abbreviateNumber(
                    inflow.amount,
                    1
                  )}`}
                />
              );
            })}
            {/* <CardItem
              title="Active Cards"
              cardIcon={group}
              value="1,254"
            /> */}
            {/* <CardItem
              title="Active POS Terminals"
              cardIcon={pos}
              value="550"
            /> */}
            <CardItem
              title="All KYC Requests"
              cardIcon={card}
              value={
                abbreviateNumber(userAnalytics?.allKycRequests, 0) as string
              }
            />
          </div>
          {/* Status Report */}
          <StatusReport dateFrom={value.startDate} dateTo={value.endDate} />
          {/* Transaction History */}
          <TransactionHistory transactions={transHistory?.results} />
        </div>
        <div className="flex flex-col gap-y-8">
          {/* View Admins */}
          {/* <ViewAdmins /> */}
          {/* Most used */}
          <MostUsed />
          {/* All Transactions */}
          <AllTransactions
            transactions={data?.transactions}
            totalTransactionVolume={data?.totalTransactionVolume}
          />
          {/* Device Type */}
          <DeviceType loginDeviceTypes={userAnalytics?.loginDeviceTypes} />
        </div>
      </div>
    </>
  );
}
