import close from "assets/close.svg";
import { useFormik } from "formik";
import { array, object } from "yup";
import CreatableSelect from "react-select/creatable";

import { useMigrationActions } from "../useMigrationHandlers";
import { AnyObject } from "utils/types";
import Button from "components/Button";

interface TagsProps {
  onClose: () => void;
  data: AnyObject;
}

export interface TagsValues {
  tags: Array<string>;
}

const Tags = ({ onClose, data }: TagsProps) => {
  const { handleTag, loading } = useMigrationActions(onClose);

  const { handleSubmit, setFieldValue, handleBlur } =
    useFormik<TagsValues>({
      initialValues: {
        tags: [],
      },
      validationSchema: object({
        tags: array()
          .min(1, "You can't leave this blank.")
          .required("You can't leave this blank."),
      }),
      onSubmit: (values) => {
        const payload = {
          tags: values.tags,
          leadId: data.id,
        };
        handleTag(payload);
      },
    });
  const onChange = (data: any) => {
    setFieldValue(
      "tags",
      data.map((d: any) => d.label)
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-20 rounded overflow-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-1">
        <p className="text-black text-base font-bold">Set Tags</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b  border-btnInactive">
        <CreatableSelect
          onChange={(val) => onChange(val)}
          onBlur={handleBlur}
          isMulti
          options={[]}
        />
      </div>

      <div className="px-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Set" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default Tags;
