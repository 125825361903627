import EmptyTable from "components/EmptyTable";
import Pagination from "components/Pagination";
import Status from "components/Status";
import Tier from "components/Tier";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { convertDate, formatNumber } from "utils/fx";
import { accounts } from "utils/tableHeaders";
import { AnyObject, ContextType } from "utils/types";

export default function Accounts() {
  const { data, next, prev } = useOutletContext<ContextType>();
  const navigate = useNavigate();

  if (data?.wallets?.length === 0) {
    return <EmptyTable />;
  }

  return (
    <>
      <div className="py-6 px-10 bg-white border border-btnInactive gap-4 grid grid-cols-8 items-center">
        {accounts.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
      </div>

      {data?.wallets?.map((result: AnyObject) => (
        <Link
          key={result.walletId}
          to={`/accounts/${result.walletId}`}
          className="py-6 px-10 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-8 items-center"
        >
          <Status
            width="w-3/4"
            status={
              result.pndActive ? "PND" : result.active ? "active" : "inactive"
            }
          />
          <p className="font-normal text-sm text-blackDark break-words">
            {result.accountName}
          </p>
          <p className="font-normal text-sm text-blackDark break-words">
            {result.accountNumber}
          </p>
          <p
            role="button"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/users/${result?.userId}`);
            }}
            className="font-normal text-sm text-blackDark break-words underline"
          >
            {result.userName}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {result.currency}
          </p>
          <p className="font-normal text-sm text-blackDark break-words">
            {formatNumber(+result.availableBalance, result.currency)}
          </p>
          <Tier status={result.accountTier} />

          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.createdAt))}
          </p>
        </Link>
      )) || <EmptyTable />}
      <Pagination
        data={data as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />
    </>
  );
}
