import EmptyTable from "components/EmptyTable";
import Pagination from "components/Pagination";
import Status from "components/Status";
import { startCase } from "lodash";
import { Link, useOutletContext } from "react-router-dom";
import { convertDate } from "utils/fx";
import { allUsers } from "utils/tableHeaders";
import { AnyObject, ContextType } from "utils/types";

export const getUserStatus = (user: AnyObject) => {
  if (user?.blackListed) {
    return "blacklisted";
  } else if (user?.active) {
    return "active";
  } else {
    return "inactive";
  }
};

export default function Users() {
  const { data, next, prev } = useOutletContext<ContextType>();

  if (data?.results?.length === 0) {
    return <EmptyTable />;
  }

  return (
    <>
      <div className="py-6 px-12 bg-white border border-btnInactive gap-4 grid grid-cols-[100px_200px_250px_200px_1fr_1fr] items-center">
        {allUsers.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
      </div>

      {data?.results?.map((result: AnyObject) => (
        <Link
          key={result.id}
          to={`/users/${result.id}`}
          className="py-6 px-12 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[100px_200px_250px_200px__1fr_1fr] items-center"
        >
          <Status width="w-3/4" status={getUserStatus(result)} />
          <p className="font-normal text-sm text-blackDark">
            {result.firstName} {result.lastName}
          </p>
          <p className="font-normal text-sm text-blackDark break-words">
            {result.email}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {result.dialCode} {result.phoneNumber}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.createdAt))}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {startCase(result.userType)}
          </p>
        </Link>
      )) || <EmptyTable />}
      <Pagination
        data={data as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />
    </>
  );
}
