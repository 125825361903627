import Input from "components/inputs/Input";
import { useState } from "react";
import filter from "assets/filter.svg";
import Button from "components/Button";
import { useFetchExchanges } from "./useFxHandlers";
import Loader from "components/Loader";
import AccessDenied from "components/AccessDenied";
import { AnyObject } from "utils/types";
import EmptyTable from "components/EmptyTable";
import Pagination from "components/Pagination";
import Modal from "components/Modal";
import CreateExchange from "./modal/CreateExchange";
import EditExchange from "./modal/EditExchange";
import { useDebounce } from "hooks/useDebounce";

type ModalType = "create" | "edit" | undefined;

export default function FxMgt() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  const [modalType, setModalType] = useState<ModalType>(undefined);
  const [show, setShow] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState({});

  const { data, isLoading, error } = useFetchExchanges(page, useDebounce(searchTerm));

  const showModal = (type: ModalType) => {
    setShow(true);
    setModalType(type);
  };

  const onClose = () => {
    setShow(false);
    setModalType(undefined);
  };

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  if (isLoading) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }
  return (
    <>
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">FX Management</p>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div className="flex gap-2 items-center mt-2">
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Currency"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          <div className="flex gap-2 justify-end ml-36">
            <Button
              size="medium"
              label="Create Currency"
              type="button"
              onClick={() => showModal("create")}
            />
          </div>
        </div>
      </div>

      <div className="py-6 px-12 bg-white border border-btnInactive gap-4 grid grid-cols-4 items-center">
        <p className="text-sm font-bold text-blackDark">From Currency</p>
        <p className="text-sm font-bold text-blackDark">To Currency</p>
        <p className="text-sm font-bold text-blackDark">Rate</p>
        <p className="text-sm font-bold text-blackDark"></p>
      </div>

      {data?.results?.map((result: AnyObject) => {
        const exchange = result?.exchange?.[0];
        return (
          <div
            key={result.id}
            className="py-6 px-12 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-4 items-center"
          >
            <p className="font-semibold text-sm text-blackDark">
              {exchange.fromCurrency}
            </p>

            <p className="font-semibold text-sm text-blackDark">
              {exchange.toCurrency}
            </p>
            <p className="font-normal text-sm text-blackDark">
              {exchange.rate}
            </p>
            <span
              role="button"
              className="font-normal text-sm text-primary"
              onClick={(e) => {
                e.preventDefault();
                showModal("edit");
                setDataToUpdate(exchange);
              }}
            >
              Edit
            </span>
          </div>
        );
      }) || <EmptyTable />}

      <Modal isShown={show}>
        {modalType === "create" && <CreateExchange onClose={onClose} />}
        {modalType === "edit" && (
          <EditExchange data={dataToUpdate} onClose={onClose} />
        )}
      </Modal>

      {data?.results?.length > 0 && (
        <Pagination data={data} prev={prev} next={next} />
      )}
    </>
  );
}
