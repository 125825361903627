import EmptyTable from "components/EmptyTable";
import Pagination from "components/Pagination";
import Status from "components/Status";
import Tier from "components/Tier";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { convertDate } from "utils/fx";
import { personalKYC } from "utils/tableHeaders";
import { AnyObject, ContextType } from "utils/types";

export default function PersonalKYC() {
  const { data, next, prev } = useOutletContext<ContextType>();
  const navigate = useNavigate();

  if (data?.results?.length === 0) {
    return <EmptyTable />;
  }

  return (
    <>
      <div className="py-6 px-10 bg-white border border-btnInactive gap-3 grid grid-cols-[120px_1.2fr_120px_120px_1fr_200px_1fr] items-center">
        {personalKYC.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
      </div>

      {data?.results?.map((result: AnyObject, index: number) => {
        return (
          <Link
            key={`${result.user} - ${index}`}
            to={`/kyc/${result.id}`}
            className="py-6 px-10 bg-white rounded-b border-b border-x border-btnInactive gap-3 grid grid-cols-[120px_1.2fr_120px_120px_1fr_200px_1fr] items-center"
          >
            <Status status={result?.status?.toLowerCase() ?? "approved"} />
            <p
              role="button"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/users/${result?.user?.id}`);
              }}
              className="font-normal text-sm text-blackDark break-words underline"
            >
              {result?.user?.firstName} {result?.user?.middleName}{" "}
              {result?.user?.lastName}
            </p>
            <Tier status={result.currentAccountTier} />

            <Tier status={result.requestedTier} />

            <p className="font-normal text-sm text-blackDark">
              {result?.user?.dialCode}
              {result?.user?.phoneNumber}
            </p>

            <p className="font-normal text-sm text-blackDark break-words">
              {result?.user?.email}
            </p>
            <p className="font-normal text-sm text-blackDark">
              {convertDate(result?.createdAt)}
            </p>
          </Link>
        );
      }) || <EmptyTable />}

      <Pagination
        data={data as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />
    </>
  );
}
