import Input from "components/inputs/Input";
import { ChangeEvent, useEffect, useState } from "react";
import filter from "assets/filter.svg";
import { useFetchMigrations } from "./useMigrationHandlers";
import Loader from "components/Loader";
import AccessDenied from "components/AccessDenied";
import { AnyObject } from "utils/types";
import EmptyTable from "components/EmptyTable";
import Pagination from "components/Pagination";
import Modal from "components/Modal";
// import bin from "assets/bin.svg";
import { convertDate } from "utils/fx";
import { useDebounce } from "hooks/useDebounce";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import CsvButton from "components/CsvButton";
import Filter from "./modal/Filter";

export default function Migrations() {
  const [searchTerm, setSearchTerm] = useState("");

  const [show, setShow] = useState(false);
  let [searchParams] = useSearchParams();

  const queryPage = searchParams.get("page") as string;
  const queryFilter = searchParams.get("filter") as string;

  const [filterTerm, setFilterTerm] = useState(queryFilter || "");

  const [page, setPage] = useState(+queryPage || 1);

  const navigate = useNavigate();
  const { user } = useAuthContext();

  const { data, isLoading, error } = useFetchMigrations(
    page,
    useDebounce(searchTerm),
    filterTerm
  );

  const canViewMigrations = user?.permissions?.includes(
    "view:migrationAssessment"
  );

  useEffect(() => {
    if (!canViewMigrations && !isLoading) {
      navigate("/home");
    }
  }, [canViewMigrations, isLoading, navigate]);

  useEffect(() => {
    if (page > 1) {
      if (filterTerm) {
        navigate({ search: `filter=${filterTerm}&page=${page}` });
      } else {
        navigate({ search: `page=${page}` });
      }
    } else if (filterTerm) {
      navigate({ search: `filter=${filterTerm}` });
    } else {
      navigate({ search: "" });
    }
  }, [filterTerm, navigate, page]);

  const showModal = () => {
    setShow(true);
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterTerm(e.target.value);
    setShow(false);
  };

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  if (isLoading) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  const csvData = [
    [
      "Account Name",
      "Account Email",
      "Date Created",
      "Date Modified",
      "Status",
      "Assigned to",
    ],
  ];
  const getCSVData = () => {
    if (data?.results?.length === 0) {
      return [];
    }

    for (let i = 0; i < data?.results?.length; i++) {
      let arr: string[] = [];
      arr.push(data?.results[i]?.name);
      arr.push(data?.results[i]?.email);
      arr.push(`${data?.results[i]?.dialCode} ${data?.results[i]?.phone}`);
      arr.push(`${convertDate(new Date(data?.results[i]?.createdAt))}`);
      arr.push(`${convertDate(new Date(data?.results[i]?.updatedAt))}`);
      arr.push(data?.results[i]?.status);
      arr.push(data?.results[i]?.assignedTo?.userName);

      csvData.push(arr);
    }

    return csvData;
  };
  return (
    <>
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">
          Migration assessment - {data?.totalResults}
        </p>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div
            role="button"
            className="flex gap-2 items-center mt-2"
            onClick={showModal}
          >
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          <div className="flex gap-2 justify-end ml-36">
            <CsvButton
              data={getCSVData()}
              filename={`migration-leads-${page}.csv`}
            />
          </div>
        </div>
      </div>

      <div className="py-4 px-6 bg-white border border-btnInactive gap-4 grid grid-cols-[200px_200px_1fr_1fr_2fr_1fr] items-start">
        <p className="text-sm font-bold text-blackDark">Account Name</p>
        <p className="text-sm font-bold text-blackDark">Account Email</p>
        <p className="text-sm font-bold text-blackDark">Date Created</p>
        <p className="text-sm font-bold text-blackDark">Date Modified</p>
        <p className="text-sm font-bold text-blackDark">Status</p>
        <p className="text-sm font-bold text-blackDark">Assigned to</p>
        <p className="text-sm font-bold text-blackDark"></p>
      </div>

      {data?.results?.map((result: AnyObject) => {
        return (
          <Link
            to={`/migration-assessment/${result.id}`}
            key={result.id}
            className="py-4 px-6 bg-white rounded-b border-b border-btnInactive gap-4 grid grid-cols-[200px_200px_1fr_1fr_2fr_1fr] items-start"
          >
            <p className="font-semibold text-sm text-primary">{result.name}</p>

            <p className="font-normal text-sm text-blackDark break-words">
              {result.email}
            </p>
            <p className="font-normal text-sm text-blackDark">
              {convertDate(new Date(result.createdAt))}
            </p>
            <p className="font-normal text-sm text-blackDark">
              {convertDate(new Date(result.updatedAt))}
            </p>
            <span className="font-normal text-sm text-blackDark rounded py-1 px-2 bg-[#0060390D] inline-flex w-fit">
              {result?.status}
            </span>
            {result?.assignedTo?.userName ? (
              <span className="font-normal text-sm text-blackDark rounded py-1 px-2 bg-[#0060390D] inline-flex w-fit">
                {result?.assignedTo?.userName}
              </span>
            ) : (
              <span className="font-normal text-sm text-blackDark">
                Not yet
              </span>
            )}
            {/* <img
              src={bin}
              alt="delete"
              role="button"
              className="font-normal text-sm text-primary"
              onClick={(e) => {
                e.preventDefault();
                showModal();
              }}
            /> */}
          </Link>
        );
      }) || <EmptyTable />}

      <Modal isShown={show}>
        <Filter
          onClose={() => setShow(false)}
          onChange={onChange}
          filterTerm={filterTerm}
        />
      </Modal>

      {data?.results?.length > 0 && (
        <Pagination data={data} prev={prev} next={next} />
      )}
    </>
  );
}
