import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";

const accountsUrl = (page: number, type: string, filter?: string) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    pageSize: 30,
    sortBy: "date",
  });
  if (type === "all") {
    return `wallets/v1/all?${query}`;
  } else {
    return `wallets/v1/all?${query}&userType=${type}`;
  }
};

// Get all Users
const getAccounts = async (page: number, type: string, filter?: string) => {
  const res = await api.get(accountsUrl(page, type, filter));
  return res.data.data;
};
export const useFetchAccounts = (
  page: number,
  type: string,
  filter?: string
) => {
  const { data, isLoading, isError, error } = useQuery(
    ["accounts", page, type, filter],
    () => getAccounts(page, type, filter),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get single Account
const getAccount = async (id: string) => {
  const res = await api.get(`wallets/v1/${id}`);
  return res.data.data.wallet;
};
export const useFetchAccount = (id: string) => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["account", id],
    () => getAccount(id),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
    refetch
  };
};

//  wallet transactions
const getWalletTransactions = async (
  page: number,
  walletId: string,
  userId: string,
  filter?: string
) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    pageSize: 30,
    walletId,
    userId,
    sortBy: "date",
  });
  const res = await api.get(`wallets/v1/transactions?${query}`);
  return res.data.data;
};
export const useFetchWalletTransactions = (
  page: number,
  walletId: string,
  userId: string,
  filter?: string
) => {
  const { data, isLoading, isError, error } = useQuery(
    ["walletTransactions", page, filter, walletId, userId],
    () => getWalletTransactions(page, walletId, userId, filter),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    data,
    loading: isLoading,
    isError,
    error,
  };
};

// Action handlers
export const useAccountActions = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    ({accountNumber, ...rest}: { reason: string; pndStatus: boolean; attachments: string[]; accountNumber: string }) => {
      return api.put(`wallets/v1/account/${accountNumber}/pnd`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("PND successfully removed");
        queryClient.invalidateQueries([]);
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const handleActivateAccount = (
    data: { reason: string; pndStatus: boolean; attachments: string[] },
    accountNumber: string
  ) => {
    mutate({...data, accountNumber});
  };

  return {
    handleActivateAccount,
    loading: isLoading,
    isError: isError,
    error: error,
  };
};
