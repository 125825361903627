import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Root from "pages/public/Root";
import Login from "pages/public/Login";
import LoginSuccess from "pages/public/LoginSuccess";
import ChangePassword from "pages/public/ChangePassword";
import ForgotPassword from "pages/public/ForgotPassword";
import VerifyOTP from "pages/public/VerifyOTP";
import PasswordChangeSuccess from "pages/public/PasswordChangeSuccess";
import PrivateRoute from "pages/authenticated/PrivateRoute";
import Home from "pages/authenticated/home/Home";
import Metrics from "pages/authenticated/Metrics";
import Users from "pages/authenticated/users/Users";
import Bills from "pages/authenticated/Bills";
import Loans from "pages/authenticated/Loans";
import Fees from "pages/authenticated/Fees";
import Settings from "pages/authenticated/Settings";
import Personal from "pages/authenticated/users/Individual";
import UsersLayout from "./pages/authenticated/users/UsersLayout";
import Business from "pages/authenticated/users/Business";
import User from "pages/authenticated/users/User";
import Blacklisted from "pages/authenticated/users/Blacklisted";
import KYCLayout from "pages/authenticated/kyc/KYCLayout";
import PersonalKYC from "pages/authenticated/kyc/PersonalKYC";
import BusinessKYC from "pages/authenticated/kyc/BusinessKYC";
import Administrators from "pages/authenticated/admin/Administrators";
import ManageRole from "pages/authenticated/admin/ManageRole";
import EditRole from "pages/authenticated/admin/EditRole";
import CreateRole from "pages/authenticated/admin/CreateRole";
import KYCDetails from "pages/authenticated/kyc/KYCDetails";
import Transaction from "pages/authenticated/transactions/Transaction";
import UserTransactions from "pages/authenticated/users/UserTransactions";
import Cards from "pages/authenticated/cards/Cards";
import CardsLayout from "pages/authenticated/cards/CardsLayout";
import CardsTransactions from "pages/authenticated/cards/TransactionHistory";
import Error from "components/Error";
import CardDetails from "pages/authenticated/cards/CardDetails";
import FxMgt from "pages/authenticated/fxMgt/FxMgt";
import AccountsLayout from "pages/authenticated/accounts/AccountsLayout";
import Accounts from "pages/authenticated/accounts/Accounts";
import PersonalAccounts from "pages/authenticated/accounts/PersonalAccounts";
import BusinessAccounts from "pages/authenticated/accounts/BusinessAccounts";
import Account from "pages/authenticated/accounts/Account";
import TransactionsLayout from "pages/authenticated/transactions/TransactionsLayout";
import LocalTransactions from "pages/authenticated/transactions/LocalTransactions";
import CrossBorderTransactions from "pages/authenticated/transactions/CrossBorderTransactions";
import UserAccount from "pages/authenticated/users/UserAccount";
import UserCard from "./pages/authenticated/users/UserCard";
import UserSettings from "pages/authenticated/users/UserSettings";
import Migrations from "pages/authenticated/migration/Migrations";
import Migration from "pages/authenticated/migration/Migration";
import Details from "pages/authenticated/migration/Details";
import Comments from "pages/authenticated/migration/Comments";
import SubAccount from "pages/authenticated/users/SubAccount";
import SubAccountDetails from "pages/authenticated/users/SubAccountDetails";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Root />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Navigate to="/login" replace /> },
      { path: "login", Component: () => <Login /> },
      { path: "login-success", Component: () => <LoginSuccess /> },
      { path: "change-password", Component: () => <ChangePassword /> },
      { path: "forgot-password", Component: () => <ForgotPassword /> },
      { path: "forgot-password/verify-otp", Component: () => <VerifyOTP /> },
      {
        path: "password-change-success",
        Component: () => <PasswordChangeSuccess />,
      },
      { path: "*", Component: () => <Navigate to="/login" replace /> },
    ],
  },

  {
    path: "*",
    element: <PrivateRoute />,
    errorElement: <Error />,
    children: [
      { path: "home", Component: () => <Home /> },
      {
        path: "kyc",
        element: <KYCLayout />,
        children: [
          { index: true, Component: () => <PersonalKYC /> },
          { path: "business", Component: () => <BusinessKYC /> },
        ],
      },
      { path: "kyc/:id", Component: () => <KYCDetails /> },

      {
        path: "users",
        element: <UsersLayout />,
        children: [
          { index: true, Component: () => <Users /> },
          { path: "individual", Component: () => <Personal /> },
          { path: "business", Component: () => <Business /> },
          { path: "blacklisted", Component: () => <Blacklisted /> },
        ],
      },
      { path: "users/:id/transactions", Component: () => <UserTransactions /> },
      {
        path: "users/:id",
        element: <User />,
        children: [
          {
            index: true,
            Component: () => <UserTransactions showTitle={false} />,
          },
          { path: "accounts", Component: () => <UserAccount /> },
          { path: "cards", Component: () => <UserCard /> },
          { path: "settings", Component: () => <UserSettings /> },
          { path: "sub-account", Component: () => <SubAccount /> },
          { path: "sub-account/:accountNumber/wallet/:walletId", Component: () => <SubAccountDetails /> },

        ],
      },

      {
        path: "transactions",
        element: <TransactionsLayout />,
        children: [
          { index: true, Component: () => <LocalTransactions /> },
          {
            path: "cross-border",
            Component: () => <CrossBorderTransactions />,
          },
        ],
      },
      { path: "transactions/:id", Component: () => <Transaction /> },

      {
        path: "accounts",
        element: <AccountsLayout />,
        children: [
          { index: true, Component: () => <Accounts /> },
          { path: "individual", Component: () => <PersonalAccounts /> },
          { path: "business", Component: () => <BusinessAccounts /> },
        ],
      },

      { path: "accounts/:id", Component: () => <Account /> },

      {
        path: "cards",
        element: <CardsLayout />,
        children: [
          { index: true, Component: () => <Cards /> },
          {
            path: "transaction-history",
            Component: () => <CardsTransactions />,
          },
        ],
      },
      { path: "cards/:id", Component: () => <CardDetails /> },

      { path: "fx-management", Component: () => <FxMgt /> },
      { path: "migration-assessment", Component: () =>  <Migrations />},
      {path: "migration-assessment/:id", Component: () => <Migration />,
        children: [
          {
            index: true,
            Component: () => <Details />,
          },
          { path: "timeline", Component: () => <UserAccount /> },
          { path: "website-activity", Component: () => <UserCard /> },
          { path: "comments", Component: () => <Comments /> },
        ],
      },

      { path: "bill-payments", Component: () => <Bills /> },
      { path: "metrics", Component: () => <Metrics /> },
      { path: "loans", Component: () => <Loans /> },
      { path: "fees", Component: () => <Fees /> },
      { path: "administrators", Component: () => <Administrators /> },
      { path: "manage-role", Component: () => <ManageRole /> },
      { path: "create-role", Component: () => <CreateRole /> },
      { path: "edit-role/:roleId", Component: () => <EditRole /> },

      { path: "settings", Component: () => <Settings /> },
    ],
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
