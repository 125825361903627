import back from "assets/back.svg";
import Button from "components/Button";
import Status from "components/Status";
import { capitalize } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnyObject } from "utils/types";
import Modal from "components/Modal";
import { useGetBusiness } from "./useKycHandlers";
import BusinessActions from "./modals/BusinessActions";
import Document from "components/Document";
import { Doc, ViewDocument } from "components/ViewDocument";

const arrayOfFiles = [
  "Cac Document",
  "Director Form",
  "Memo of Association",
  "Proof of Address",
  "Tin Certificate",
];

type ModalType = "approve" | "reject" | undefined;
function BusinessKYCDetails({ data }: { data: AnyObject }) {
  const navigate = useNavigate();

  const [modal, setModal] = useState<ModalType>(undefined);

  const [imageToShow, setImageToShow] = useState("");
  const [type, setType] = useState("");

  const showDocument: Doc = (image: string, type: string) => {
    setImageToShow(image);
    setType(type);
  };

  const { data: business, isLoading } = useGetBusiness(data.business);
  const onClose = () => setModal(undefined);
  return (
    <>
      <div
        className="pt-2 mb-4 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate(-1)}
      >
        <img src={back} alt="back" />
        KYC
      </div>
      {/*  */}
      <div className="grid grid-cols-[1fr_120px_120px] gap-4 items-center">
        {data?.status && (
          <Status
            status={data?.status?.toLowerCase() ?? "approved"}
            width="w-32 mb-4"
          />
        )}

        {/* Buttons */}
        {!business?.business?.verified && !isLoading && (
          <>
            <Button
              variant="primary"
              label="Approve"
              onClick={() => setModal("approve")}
            />
            <Button
              variant="danger"
              label="Reject"
              onClick={() => setModal("reject")}
            />
          </>
        )}
      </div>

      {/*  */}
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <div className="border-b border-grey pb-3 mb-4">
          <p className="text-blackDark text-base font-bold">Business Details</p>
        </div>

        <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">Company Name</p>
            <p className="text-blackDark text-sm font-medium">
              {business?.business?.businessName || "N/A"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Address</p>
            <p className="text-blackDark text-sm font-medium">
              {data.address.street} {data.address.street2}, {data.address.city}, {data.address.state}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">RC/BN Number</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.rcNumber || "N/A"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Tin Number</p>
            <p className="text-blackDark text-sm font-medium">
              {business?.business?.tinNumber || "N/A"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">ID Type</p>
            <p className="text-blackDark text-sm font-medium">
              {capitalize(data?.idType)}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Status Message</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.statusMessage}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Industry</p>
            <p className="text-blackDark text-sm font-medium">
              {business?.business?.industry || "N/A"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Type</p>
            <p className="text-blackDark text-sm font-medium">
              {business?.business?.type || "N/A"}
            </p>
          </div>

          <ViewDocument
            file={business?.business?.proofOfAddress?.proof}
            name="Proof of Address"
            showDocument={() =>
              showDocument(
                business?.business?.proofOfAddress?.proof,
                "Proof of Address"
              )
            }
          />

          <ViewDocument
            file={business?.business?.cacDoc}
            name="Cac Document"
            showDocument={() =>
              showDocument(business?.business?.cacDoc, "Cac Document")
            }
          />

          <ViewDocument
            file={business?.business?.directorForm}
            name="Director Form"
            showDocument={() =>
              showDocument(business?.business?.directorForm, "Director Form")
            }
          />

          <ViewDocument
            file={business?.business?.memoOfAsso}
            name="Memo of Association"
            showDocument={() =>
              showDocument(
                business?.business?.memoOfAsso,
                "Memo of Association"
              )
            }
          />

          <ViewDocument
            file={business?.business?.tinCert}
            name="Tin Certificate"
            showDocument={() =>
              showDocument(business?.business?.tinCert, "Tin Certificate")
            }
          />
        </div>
      </div>
      <Modal isShown={!!modal || arrayOfFiles.includes(type)}>
        {modal === "approve" && (
          <BusinessActions action={modal} onClose={onClose} data={data} />
        )}
        {modal === "reject" && (
          <BusinessActions action={modal} onClose={onClose} data={data} />
        )}

        {arrayOfFiles.includes(type) && (
          <Document
            onClose={() => {
              setImageToShow("");
              setType("");
            }}
            image={imageToShow}
            type={type}
          />
        )}
      </Modal>
    </>
  );
}

export default BusinessKYCDetails;
