import EmptyTable from "components/EmptyTable";
import Pagination from "components/Pagination";
import Status from "components/Status";
import { Link, useOutletContext } from "react-router-dom";
import { convertDate } from "utils/fx";
import { businessKYC } from "utils/tableHeaders";
import { AnyObject, ContextType } from "utils/types";

export default function BusinessKYC() {
  const { data, next, prev } = useOutletContext<ContextType>();

  if (data?.results?.length === 0) {
    return <EmptyTable />;
  }
  return (
    <>
      <div className="py-6 px-10 bg-white border border-btnInactive gap-3 grid grid-cols-4 items-center">
      {businessKYC.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
      </div>

      {data?.results?.map((result: AnyObject) => {
        return (
          <Link
            key={result?.id}
            to={`/kyc/${result?.id}`}
            className="py-6 px-10 bg-white rounded-b border-b border-x border-btnInactive gap-3 grid grid-cols-4 items-center"
          >
            <Status
              width="w-1/2"
              status={result?.status?.toLowerCase() ?? "approved"}
            />
            <p className="font-normal text-sm text-blackDark">
              {result?.companyName}
            </p>
            <p className="font-normal text-sm text-blackDark break-words">
              {result?.rcNumber ?? "N/A"}
            </p>
            <p className="font-normal text-sm text-blackDark">
              {convertDate(result.createdAt)}
            </p>
          </Link>
        );
      }) || <EmptyTable />}

      <Pagination
        data={data as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />
    </>
  );
}
