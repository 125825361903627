import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";
import { AnyObject } from "utils/types";
import { TransferType } from "./TransactionsLayout";

// Transactions
const getTransactions = async (
  type: TransferType,
  page: number,
  filter?: string
) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    limit: 30,
    transferType: type === "ALL" ? "" : "",
    transactionCategory:
      type === "INTERNATIONAL" ? "CROSS_BORDER_TRANSFER" : "INFLOW,TRANSFER",
    sortBy: "transactionDate:desc",
  });
  const res = await api.get(`transactions/v1?${query}`);
  return res.data.data.transactions;
};
export const useFetchTransactions = (
  type: TransferType,
  page: number,
  filter?: string
) => {
  const { data, isLoading, isError, error } = useQuery(
    ["transactions", page, filter, type],
    () => getTransactions(type, page, filter),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!type,
    }
  );
  return {
    data,
    loading: isLoading,
    isError,
    error,
  };
};

// Single Transaction
const getTransaction = async (id: string) => {
  const res = await api.get(`transactions/v1/${id}`);
  return res.data.data.transaction;
};
export const useFetchTransaction = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["transaction", id],
    () => getTransaction(id),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
  return {
    data,
    loading: isLoading,
    isError,
    error,
  };
};

// Action handlers
export const useTransactionHandlers = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    (data: AnyObject) => {
      return api.post(`transactions/v1/re-query`, {
        transactionRef: data.transactionRef,
      });
    },
    {
      onSuccess: async (res, variables) => {
        queryClient.invalidateQueries(["transaction", variables.id]);
        toast.success("Transaction successfully re-queried!");
      },
    }
  );

  const { mutate: mutateUpdate, isLoading: loading, isError: isError_, error: error_ } = useMutation(
    (data: AnyObject) => {
      return api.put(`transactions/v1/${data.id}`, {
        status: data.status,
      });
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["transaction", variables.id]);
        toast.success("Transaction successfully updated!");
      },
    }
  );

  const handleRequeryTransaction = (data: AnyObject) => {
    mutate(data);
  };

  const handleUpdateTransaction = (data: AnyObject) => {
    mutateUpdate(data);
  };

  return {
    handleRequeryTransaction,
    handleUpdateTransaction,
    loading: isLoading || loading,
    isError: isError || isError_,
    error: error || error_,
  };
};
