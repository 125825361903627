import Status from "components/Status";
import Tier from "components/Tier";
import { startCase } from "lodash";
import { useParams } from "react-router-dom";
import { convertDate, formatNumber } from "utils/fx";
import { AnyObject,} from "utils/types";
import { useFetchUserWallets } from "./useUsersHandlers";
import Loader from "components/Loader";
import EmptyTable from "components/EmptyTable";

const UserAccount = () => {
  const param = useParams();

  const { data, isLoading } = useFetchUserWallets(param.id ?? "");

  if (isLoading) return <Loader />;

  if (data?.length === 0) {
    return <EmptyTable />;
  }

  return data?.map((item: AnyObject) => (
    <div
      key={item.walletId}
      className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm"
    >
      <div className="border-b border-grey pb-3 mb-4">
        <p className="text-blackDark text-[15px] font-bold flex items-center gap-3">
            Account Balance:
          <span className="text-primary text-lg font-medium">
            {formatNumber(
              item?.availableBalance ? +item?.availableBalance : undefined,
              item?.currency ?? "NGN"
            )}
          </span>
          <span className="bg-greenAccent text-primary  inline-flex py-1 px-4 rounded text-sm items-center justify-center">
            {item.walletType === "main" ? "Main account" : "Sub-account"}
        </span>
        </p>
       
      </div>

      <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
        <div>
          <p className="text-coolGrey text-sm mb-3">Account Name</p>
          <p className="text-blackDark text-sm font-medium">
            {item?.accountName}
          </p>
        </div>

        <div>
          <p className="text-coolGrey text-sm mb-3">Account Number</p>
          <p className="text-blackDark text-sm font-medium">
            {item?.accountNumber}
          </p>
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">Provider</p>
          <p className="text-blackDark text-sm font-medium">{item?.provider}</p>
        </div>

        <div>
          <p className="text-coolGrey text-sm mb-3">Account Type</p>
          <p className="text-blackDark text-sm font-medium">
            {startCase(item?.userType)}
          </p>
        </div>
        
        <div>
          <p className="text-coolGrey text-sm mb-3">Date Created</p>
          <p className="text-blackDark text-sm font-medium">
            {" "}
            {convertDate(item.createdAt)}
          </p>
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">Tier Status</p>
          <Tier status={item.accountTier} />
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">Wallet Status</p>
          <Status status={item.pndActive ? "PND" : item.active ? "active" : "inactive"} width="w-3/4" />
        </div>
      </div>
    </div>
  ));
};

export default UserAccount;
