import Button from "components/Button";
import { AnyObject } from "utils/types";
import close from "assets/close.svg";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useTransactionHandlers } from "../useTransactionHandlers";
import Select from "components/inputs/Select";
import { object, string } from "yup";
import { useFormik } from "formik";

interface UpdateProps {
  onClose: () => void;
  data: AnyObject;
}

const UpdateTransaction = ({ onClose, data }: UpdateProps) => {
  const { handleUpdateTransaction, isError, error, loading } =
    useTransactionHandlers(onClose);
    
  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<{ status: string }>({
      initialValues: {
        status: "",
      },
      validationSchema: object({
        status: string()
          .oneOf(["FAILED", "PROCESSING", "SUCCESSFUL"])
          .required("Status must be one of FAILED, PROCESSING, SUCCESSFUL"),
      }),
      onSubmit: (values) => {
        const payload = {status: values.status, id: data.id};
        handleUpdateTransaction(payload);
      },
    });
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-48 rounded"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Update Transaction</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b border-btnInactive">
        <Select
          id="status"
          name="status"
          label="Status"
          value={values.status}
          data={[
            { value: "FAILED", label: "FAILED" },
            { value: "PROCESSING", label: "PROCESSING" },
            { value: "SUCCESSFUL", label: "SUCCESSFUL" },
          ].filter(item => item.value !== data.status)}
          onChange={handleChange}
          placeholder={"Select status"}
          error={`${!!errors["status"] && !!touched["status"]}`}
          errorMessage={errors["status"]}
          onBlur={handleBlur}
        />
      </div>

      <div className="px-4 py-2 flex gap-4 justify-between items-center">
        <Button
          variant="neutral"
          label="No, Cancel"
          onClick={onClose}
          size="medium"
        />
        <Button
          label="Yes, Update"
          type="submit"
          size="medium"
          loading={loading}
        />
      </div>
    </form>
  );
};

export default UpdateTransaction;
