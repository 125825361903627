import { toast } from "react-hot-toast";
import { AnyObject } from "utils/types";

export const convertDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return new Date(date).toLocaleDateString("en-US", options);
};

export const convertTime = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  return date.toLocaleTimeString("en-US", options);
};

export const computedTier = (status: string) =>
  status === "3" ? status : status + 1;

export const formatNumber = (number: number | undefined, currency: string) => {
  if (
    number === undefined ||
    number === null ||
    currency === null ||
    currency === undefined
  )
    return "N/A";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(number);
};

export const abbreviateNumber = (num: number, precision = 2) => {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
};

export const copyToClipboard = async (text: string) => {
  try {
    await navigator?.clipboard?.writeText(text);
    toast.success("Successfully copied text");
  } catch (error) {
    toast.error("Unable to copy text");
  }
};

export const buildQuery = (data: any) => {
  if (typeof data === "string") return data;

  let query = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        query.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        );
      }
    }
  }
  return query.join("&");
};

export const blobToImage = (blob: Blob) => {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(blob);
    let img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img);
    };
    img.src = url;
  });
};

export const getKYCObject = (data: AnyObject) => {
  if (data?.proofOfAddress?.proof !== "") {
    return data?.proofOfAddress;
  }
  if (data?.identity?.applicationDate) {
    return data?.identity;
  }
  if (data?.bvn?.applicationDate) {
    return data?.bvn;
  }

  return null;
};

export const formatReference = (value: string) => {
  if (!value) return;
  return `${value?.slice(0, 12)}....${value?.slice(-4)}`;
};

export const currencies = [
  { label: "CAD", value: "CAD" },
  { label: "GBP", value: "GBP" },
  { label: "NGN", value: "NGN" },
  { label: "USD", value: "USD" },
];
