import close from "assets/close.svg";
import { useFormik } from "formik";
import { object, string } from "yup";

import { useMigrationActions } from "../useMigrationHandlers";
import { AnyObject } from "utils/types";
import Button from "components/Button";
import Input from "components/inputs/Input";

interface ConsultantProps {
  onClose: () => void;
  data: AnyObject;
}

export interface ConsultantValues {
  userName: string;
  email: string;
}

const Consultant = ({ onClose, data }: ConsultantProps) => {
  const { handleConsultant, loading } = useMigrationActions(onClose);

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik<ConsultantValues>({
      initialValues: {
        userName: "",
        email: "",
      },
      validationSchema: object({
        userName: string().required("A Consultant name is required"),
        email: string().required("A Consultant email is required"),
      }),
      onSubmit: (values) => {
        const payload = {
            consultant: {
                userName: values.userName,
                email: values.email,
            },
            leadId: data.id,
        }
        handleConsultant(payload);
      },
    });

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-20 rounded overflow-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-1">
        <p className="text-black text-base font-bold">Set Consultant</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b  border-btnInactive">
        <Input
          label="Consultant Name"
          id="userName"
          name="userName"
          value={values.userName}
          onChange={handleChange}
          placeholder={"Enter consultant name"}
          error={`${!!errors["userName"] && !!touched["userName"]}`}
          errorMessage={errors["userName"]}
          onBlur={handleBlur}
        />

        <Input
          type="email"
          label="Email"
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          placeholder={"Enter email"}
          error={`${!!errors["email"] && !!touched["email"]}`}
          errorMessage={errors["email"]}
          onBlur={handleBlur}
        />
      </div>

      <div className="px-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Set" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default Consultant;
