import { startCase } from "lodash";
import { formatNumber } from "utils/fx";

export default function AllTransactions({
  transactions,
  totalTransactionVolume,
}: {
  transactions: Array<{ [key: string]: string }>;
  totalTransactionVolume: string;
}) {
  return (
    <div className="bg-white p-5 rounded">
      <div className="flex justify-between items-center">
        <p className="text-blackDark font-bold">All Transactions</p>
      </div>
      <hr className="my-3 border-t-[0.5px] border-t-coolGrey" />
      <div className="flex flex-col justify-center items-center">
        {/* <p className="text-lg text-blackDark mb-2 font-bold">₦295,000.04</p> */}
        <small className="text-coolGrey font-bold">{totalTransactionVolume} transactions</small>
      </div>
      
      {transactions?.map(transaction => (
        <div key={transaction.category} className="mt-3 flex justify-between items-center border-t-[0.5px] border-t-btnInactive">
        <div className="flex flex-col items-start">
          <p className="text-blackDark mt-3 font-bold text-sm">{startCase(transaction.category)}</p>
          <small className="text-coolGrey text-sm font-light">
            {transaction.totalcount} transactions
          </small>
        </div>
        <p className="text-blackDark font-medium">
          {formatNumber(+transaction.amount, transaction.currency)}
        </p>
      </div>
      ))}
      

     
    </div>
  );
}
