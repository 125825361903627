import close from "assets/close.svg";
import { useFormik } from "formik";
import { object, string } from "yup";

import { useMigrationActions } from "../useMigrationHandlers";
import { AnyObject } from "utils/types";
import Button from "components/Button";
import check from "assets/check.svg";

interface StatusProps {
  onClose: () => void;
  data: AnyObject;
}

export interface StatusValues {
  status: string;
  leadId: string;
}

const statuses = [
  "Submitted",
  "Incomplete",
  "Scheduled appointment with a consultant",
  "Met with consultant",
  "Subscribe to service",
  "Customer Not Ready",
  "Migration Journey ongoing",
  "Migration Journey Completed",
  "Group Consultation Done"
];
const SetStatus = ({ onClose, data }: StatusProps) => {
  const { handleStatus, loading } = useMigrationActions(onClose);

  const { handleSubmit, setFieldValue, values } = useFormik<StatusValues>({
    initialValues: {
      status: "",
      leadId: data.id,
    },
    validationSchema: object({
      status: string().required("A status must be selected"),
    }),
    onSubmit: (values) => {
      handleStatus(values);
    },
  });

  const filtered = statuses.filter((stat) => stat !== data.status);

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[350px] m-auto mt-10 rounded overflow-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-1">
        <p className="text-black text-base font-bold">Set Status</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
       { filtered?.map((item) => (
          <div
            role="button"
            key={item}
            onClick={() => setFieldValue("status", item)}
            className={`p-2 border-b cursor-pointer border-btnInactive flex items-center justify-between ${
              item === values.status ? "bg-btnInactive" : ""
            } `}
          >
            <p className="text-sm">{item}</p>
            {item === values.status && (
              <span className="inline-flex items-center justify-center w-3 h-3 rounded-full bg-primary">
                <img src={check} alt="check" className="w-2 h-2" />
              </span>
            )}
          </div>
        ))}
      <div className="px-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Save" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default SetStatus;
