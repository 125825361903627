import { useNavigate, useParams } from "react-router-dom";
import back from "assets/back.svg";
import { useDocumentTitle } from "hooks/useDocument";
import {
  useFetchTransaction,
  useTransactionHandlers,
} from "./useTransactionHandlers";
import Status from "components/Status";
import {
  convertDate,
  convertTime,
  copyToClipboard,
  formatNumber,
  formatReference,
} from "utils/fx";
import copy from "assets/copy-grey.svg";
import Loader from "components/Loader";
import Button from "components/Button";
import { useEffect, useState } from "react";
import { AnyObject } from "utils/types";
import { toast } from "react-hot-toast";
import { startCase, upperCase } from "lodash";
import Modal from "components/Modal";
import UpdateTransaction from "./modals/UpdateTransaction";
import { useAuthContext } from "hooks/useAuthContext";

export default function Transaction() {
  useDocumentTitle("Transaction");
  const { id } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const {user } = useAuthContext()

  const canViewTransactions = user?.permissions?.includes('view:transactions:all')


  const { data, loading } = useFetchTransaction(id ?? "");

  useEffect(() => {
    if(!canViewTransactions && !loading) {
      navigate('/home')
    }
  }, [canViewTransactions, loading, navigate])
  const {
    handleRequeryTransaction,
    loading: isLoading,
    isError,
    error,
  } = useTransactionHandlers();

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  if (loading) return <Loader />;

  const onClose = () => {
    setShow(false);
  };

  return (
    <>
      <div
        className="pt-2 mb-4 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate(-1)}
      >
        <img src={back} alt="back" />
        All Transactions
      </div>
      {/*  */}
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <div className="border-b border-grey pb-3 mb-4">
          <p className="text-blackDark text-base font-bold">Account Details</p>
        </div>

        <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">Account Name</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.source?.accountName}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Email</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.author?.email}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Number</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.source?.accountNo}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Bank Name</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.source?.bankName}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Phone Number</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.author?.mobile}
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <Status status={data?.status?.toLowerCase()} width="w-32" />
        {data?.requeryStatus.toLowerCase() === "pending" &&
          !data?.isManuallyProcessed &&
          !["successful", "failed"].includes(data?.status?.toLowerCase()) && (
            <div className="grid grid-cols-[120px] gap-4">
              <Button
                loading={isLoading}
                variant="primary"
                label="Re-Query"
                onClick={() =>
                  handleRequeryTransaction({
                    transactionRef: data?.transactionRef,
                    id: data?.id,
                  })
                }
              />
            </div>
          )}

        {data?.isManuallyProcessed &&
          data?.transactionCategory === "CROSS_BORDER_TRANSFER" &&
          data?.status.toLowerCase() !== "successful" && (
            <div className="grid grid-cols-[120px] gap-4">
              <Button
                loading={isLoading}
                variant="primary"
                label="Update Status"
                onClick={() => setShow(!show)}
              />
            </div>
          )}
      </div>

      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm mt-4">
        <div className="border-b border-grey pb-3 mb-4">
          <p className="text-blackDark text-base font-bold">
            Transaction Details
          </p>
        </div>

        <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">Type</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.transactionType}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Transaction Reference</p>
            <p className="font-medium text-sm text-blackDark inline-flex gap-1">
              <span>{formatReference(data?.transactionRef)}</span>
              <img
                src={copy}
                alt="copy"
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard(data?.transactionRef);
                }}
              />
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Amount</p>
            <p className="text-blackDark text-sm font-medium">
              {formatNumber(data?.amount, data?.currency ?? "NGN")}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Date</p>
            <p className="text-blackDark text-sm font-medium">
              {convertDate(new Date(data?.transactionDate))} <br />{" "}
              {convertTime(new Date(data?.transactionDate))}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Recipient Name</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.beneficiary?.accountName}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Recipient Account</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.beneficiary?.accountNo}
            </p>
          </div>
          {data?.beneficiary?.address && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient Address</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.address}
              </p>
            </div>
          )}

          {data?.beneficiary?.city && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient City</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.city}
              </p>
            </div>
          )}

          {data?.beneficiary?.state && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient State</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.state}
              </p>
            </div>
          )}

          {data?.beneficiary?.country && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient Country</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.country}
              </p>
            </div>
          )}

          {data?.beneficiary?.zipCode && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient Zip Code</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.zipCode}
              </p>
            </div>
          )}

          {data?.beneficiary?.sortCode && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient Sort Code</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.sortCode}
              </p>
            </div>
          )}

          {data?.beneficiary?.swiftCode && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient Swift Code</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.swiftCode}
              </p>
            </div>
          )}

          {data?.beneficiary?.routingNumber && (
            <div>
              <p className="text-coolGrey text-sm mb-3">
                Recipient Routing Number
              </p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.routingNumber}
              </p>
            </div>
          )}
          <div>
            <p className="text-coolGrey text-sm mb-3">Recipient Bank</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.beneficiary?.bankName}
            </p>
          </div>

          {/*  */}

          {data?.beneficiary?.bankAddress && (
            <div>
              <p className="text-coolGrey text-sm mb-3">
                Recipient Bank Address
              </p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.bankAddress}
              </p>
            </div>
          )}

          {data?.beneficiary?.bankCity && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient Bank City</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.bankCity}
              </p>
            </div>
          )}

          {data?.beneficiary?.bankState && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient Bank State</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.bankState}
              </p>
            </div>
          )}

          {data?.beneficiary?.bankCountry && (
            <div>
              <p className="text-coolGrey text-sm mb-3">
                Recipient Bank Country
              </p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.bankCountry}
              </p>
            </div>
          )}

          {data?.beneficiary?.bankCode && (
            <div>
              <p className="text-coolGrey text-sm mb-3">Recipient Bank Code</p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.bankCode}
              </p>
            </div>
          )}

          {data?.beneficiary?.bankZipCode && (
            <div>
              <p className="text-coolGrey text-sm mb-3">
                Recipient Bank ZipCode
              </p>
              <p className="text-blackDark text-sm font-medium">
                {data?.beneficiary?.bankZipCode}
              </p>
            </div>
          )}
          <div>
            <p className="text-coolGrey text-sm mb-3">Transaction Category</p>
            <p className="text-blackDark text-sm font-medium">
              {upperCase(startCase(data?.transactionCategory))}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Details</p>
            <p className="text-blackDark text-sm font-medium text-ellipsis overflow-hidden">
              {data?.description}
            </p>
          </div>
        </div>
      </div>

      <Modal isShown={show}>
        <UpdateTransaction data={data} onClose={onClose} />
      </Modal>
    </>
  );
}
