import Button from "components/Button";
import { useUserHandlers } from "../useUsersHandlers";
import { startCase } from "lodash";
import { AnyObject } from "utils/types";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

interface BlacklistUserProps {
  onClose: () => void;
  type: string;
  user: AnyObject
}

const BlacklistUser = ({ onClose, type, user }: BlacklistUserProps) => {
  const {handleUserAction, loading, isError, error} = useUserHandlers();


  const handleAction = () => {
    const data = {id: user?.id, blackListed: type === "blacklist"}
    handleUserAction(data)
  }

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  return (
    <div className="bg-white w-[315px] m-auto mt-48 rounded">
      <div className="p-4">
        <p className="text-blackDark text-center mt-8 font-medium text-lg">
          Are you sure you want to {type} this account?
        </p>
      </div>

      <div className="px-4 flex flex-col justify-between items-center">
        <Button loading={loading} variant={type === "blacklist" ? "danger" : "primary"} label={startCase(type)} onClick={handleAction} />
        <Button variant="neutral" label="Cancel" onClick={onClose} />
      </div>
    </div>
  );
};

export default BlacklistUser;