import Input from "components/inputs/Input";
import filter from "assets/filter.svg";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { startCase } from "lodash";
import { useDocumentTitle } from "hooks/useDocument";
import { useFetchAccounts} from "./useAccountsHandlers";
import Loader from "components/Loader";
import { AnyObject, ContextType } from "utils/types";
import AccessDenied from "components/AccessDenied";
import CsvButton from "components/CsvButton";
import { accounts } from "utils/tableHeaders";
import { convertDate, formatNumber } from "utils/fx";
import { useAuthContext } from "hooks/useAuthContext";
import { useDebounce } from "hooks/useDebounce";



const tabs = ["all", "individual", "business"];

type tabType = (typeof tabs)[number];
export default function AccountsLayout() {

  const param = useParams<Record<"*", tabType>>();
  const [tab, setTab] = useState<tabType>("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const {user } = useAuthContext()
  const navigate = useNavigate();

  useDocumentTitle(`Accounts - ${tab || "All"}`);

  useEffect(() => {
    if (param["*"]) {
      if (tabs.includes(param["*"].split("/")[1])) {
        setTab(param["*"].split("/")[1]);
      } else {
        setTab("");
      }
    }
  }, [param]);

  const {data, error, isLoading} = useFetchAccounts(page, tab || "all", useDebounce(searchTerm) )


  const canViewAccounts = user?.permissions?.includes('view:accounts')
  useEffect(() => {
    if(!canViewAccounts && !isLoading) {
      navigate('/home')
    }
  }, [canViewAccounts, isLoading, navigate])

  if(isLoading) return <Loader />
  
  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };


  const csvData = [accounts];

  const getCSVData = () => {
    if (data?.wallets?.length === 0) {
      return [];
    }
    
    for (let i = 0; i < data?.wallets?.length; i++) {
      let arr = [];
      arr.push(data?.wallets[i].active ? 'active' : 'inactive');
      arr.push(
        `${data?.wallets[i]?.accountName}`
      );
      arr.push(data?.wallets[i]?.accountNumber);
      arr.push(
        `${data?.wallets[i]?.currency}`
      );
      arr.push(`${formatNumber(+data?.wallets[i]?.availableBalance, data?.wallets[i]?.currency)}`);
      arr.push(`Tier ${data?.wallets[i]?.accountTier}`)
      arr.push(convertDate(new Date(data?.wallets[i]?.createdAt)));

      csvData.push(arr);
    }
    return csvData;
  };

  return (
    <>
      <div className="pt-2 flex justify-start items-center gap-8 ml-16 font-medium">
        <Link
          to=""
          role="tab"
          className={`${
            tab === ""
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          } pb-4 cursor-pointer`}
        >
          All
        </Link>
        <Link
          to="individual"
          role="tab"
          className={`pb-4 cursor-pointer ${
            tab === "individual"
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          }`}
        >
          Personal
        </Link>
        <Link
          to="business"
          role="tab"
          className={`pb-4 cursor-pointer ${
            tab === "business"
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          }`}
        >
          Business
        </Link>
        
      </div>
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">{startCase(tab || "all")} - {data?.total}</p>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div className="flex gap-2 items-center mt-2">
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => 
                setSearchTerm(e.target.value)
              }
              placeholder="Account Name or Account Number"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          <div className="flex gap-2 justify-end ml-36">
          <CsvButton
              data={getCSVData()}
              filename={
                tab === ""
                  ? `All_accounts_${page}.csv`
                  : `${tab}_accounts_${page}.csv`
              }
            />
          </div>
        </div>
      </div>
      <Outlet context={{data, next, prev} satisfies ContextType} />
    </>
  );
}
