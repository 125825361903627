import close from "assets/close.svg";
import Button from "components/Button";
import Input from "components/inputs/Input";
import { useFormik } from "formik";
import { object, string } from "yup";
import { AnyObject } from "utils/types";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useUserHandlers } from "../useUsersHandlers";
import { startCase } from "lodash";

interface EditUserProps {
  onClose: () => void;
  user: AnyObject;
  type: "dateOfBirth" | "phoneNumber";
}

export interface ChangeValues {
  phoneNumber: string;
  dateOfBirth: string;
  id: string;
}
const EditUser = ({ onClose, user, type }: EditUserProps) => {
const {handleUpdateUser, loading, isError, error}   = useUserHandlers(onClose)
  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<ChangeValues>({
      initialValues: {
        dateOfBirth: user?.dateOfBirth ?? "",
        phoneNumber: user?.phoneNumber,
        id: user?.id
      },
      validationSchema: object({
        phoneNumber: string(),
        dateOfBirth: string(),
      }),
      onSubmit: (values) => {
        handleUpdateUser(values)
      },
    });
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-40 rounded"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Change {startCase(type)}</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>

      <div className="p-4 border-b  border-btnInactive">
        {type === "dateOfBirth" ? (
          <Input
            type="date"
            label="Date of Birth"
            id="dateOfBirth"
            name="dateOfBirth"
            value={new Date(values.dateOfBirth || new Date()).toISOString().slice(0, 10)}
            onChange={handleChange}
            placeholder={"Enter email"}
            error={`${!!errors["dateOfBirth"] && !!touched["dateOfBirth"]}`}
            errorMessage={errors["dateOfBirth"]}
            onBlur={handleBlur}
          />
        ) : (
          <Input
            type="text"
            label="Phone Number"
            id="phoneNumber"
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            placeholder={"Enter phone number"}
            error={`${!!errors["phoneNumber"] && !!touched["phoneNumber"]}`}
            errorMessage={errors["phoneNumber"]}
            onBlur={handleBlur}
          />
        )}
      </div>

      <div className="p-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Change" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default EditUser;
