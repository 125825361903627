import { ModalType } from "pages/authenticated/migration/Migration";

const ArrowDropdown = ({
  type,
  modal,
  toggleDropdown,
}: {
  type: ModalType;
  modal: ModalType | "";
  toggleDropdown: (val: ModalType) => void;
}) => {
  const isOpen = type === modal;
  return (
    <button
      onClick={() => toggleDropdown(type)}
      className="flex items-center justify-center w-6 h-6 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50 transition-colors duration-200 ease-in-out hover:bg-gray-100"
      aria-label="Toggle dropdown"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`transition-transform duration-200 ease-in-out ${
          isOpen ? "transform rotate-180" : ""
        }`}
      >
        <path
          d="M7 10L12 15L17 10"
          stroke="#4A5568"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};
export default ArrowDropdown;
