import { ViewDocument } from "components/ViewDocument";
import Checkbox from "components/inputs/Checkbox";
import { copyToClipboard } from "utils/fx";
import copy from "assets/copy.svg";
import { useState } from "react";
import Document from "components/Document";
import Modal from "components/Modal";
import { useOutletContext } from "react-router-dom";
import { ContextType } from "utils/types";

const UserSettings = () => {
  const { data } = useOutletContext<ContextType>();
  const [imageToShow, setImageToShow] = useState("");
  const [type, setType] = useState("");
  const showDocument = (image: string, type: string) => {
    setImageToShow(image);
    setType(type);
  };
  return (
    <div className="grid grid-cols-3 gap-4">
      {/*  */}
      <div className="bg-white p-7 rounded border border-btnInactive shadow-sm">
        <div className="border-b border-grey pb-3">
          <p className="text-blackDarkDark font-bold">Account Settings</p>
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">User UUID</p>
            <p className="text-sm text-blackDarkDark font-medium">
              {data?.user?.id ?? "N/A"}
            </p>
          </div>
          <img
            onClick={() => copyToClipboard(data?.user?.id)}
            src={copy}
            alt="copy"
            className="cursor-pointer"
          />
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Daily Transaction Limit
            </p>
            <p className="text-sm text-blackDarkDark font-medium">
              NGN 1,000,000
            </p>
          </div>
          <span className="text-primary text-sm">Edit</span>
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">
              International Card Transaction Fee
            </p>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Default: <span className="text-blackDark font-medium">3.8%</span>
            </p>
            <p className="text-sm text-coolGrey font-normal">
              NGN 50,000.00 Limit:{" "}
              <span className="text-blackDark font-medium">3.8%</span>
            </p>
            <p className="text-sm text-coolGrey font-normal">
              NGN 100,000.00 Limit:{" "}
              <span className="text-blackDark font-medium">3.8%</span>
            </p>
          </div>
          <span className="text-primary text-sm">Edit</span>
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Account Transaction Fee
            </p>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Default:{" "}
              <span className="text-blackDark font-medium">1.5% + NGN 50</span>
            </p>
          </div>
          <span className="text-primary text-sm">Edit</span>
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Bank Transfer Fee (Checkout)
            </p>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Default:{" "}
              <span className="text-blackDark font-medium">1.5% + NGN 50</span>
            </p>
          </div>
          <span className="text-primary text-sm">Edit</span>
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Bank Transfer Fee (Deposit)
            </p>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Default:{" "}
              <span className="text-blackDark font-medium">1.5% + NGN 50</span>
            </p>
          </div>
          <span className="text-primary text-sm">Edit</span>
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">POS Fee</p>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Default:{" "}
              <span className="text-blackDark font-medium">0.7% + NGN 0</span>
            </p>
            <p className="text-sm text-coolGrey font-normal">
              Cap: <span className="text-blackDark font-medium">NGN 300</span>
            </p>
          </div>
          <span className="text-primary text-sm">Edit</span>
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Disbursement Fee
            </p>
            <p className="text-sm text-coolGrey font-normal mb-2">
              Default:{" "}
              <span className="text-blackDark font-medium">1.5% + NGN 50</span>
            </p>
            <p className="text-sm text-coolGrey font-normal">
              NGN 50,000.00 Limit:{" "}
              <span className="text-blackDark font-medium">3.8% + NGN50</span>
            </p>
            <p className="text-sm text-coolGrey font-normal">
              NGN 100,000.00 Limit:{" "}
              <span className="text-blackDark font-medium">3.8%</span>
            </p>
          </div>
          <span className="text-primary text-sm">Edit</span>
        </div>
      </div>

      {/*  */}
      <div className="bg-white p-7 rounded border border-btnInactive shadow-sm h-fit">
        <div className="border-b border-grey pb-3">
          <p className="text-blackDarkDark font-bold">KYC</p>
        </div>

        <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
          <div>
            <p className="text-sm text-coolGrey font-normal mb-2">BVN</p>
            <p className="text-sm text-blackDark font-medium">
              {data?.bvn?.number}
            </p>
          </div>
          <span className="text-primary text-sm">Edit</span>
        </div>

        {data?.identity?.idType && (
          <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
            <div>
              <p className="text-sm text-coolGrey font-normal mb-2">ID Type</p>
              <p className="text-sm text-blackDark font-medium">
                {data?.identity?.idType}
              </p>
            </div>
            <span className="text-primary text-sm">Edit</span>
          </div>
        )}

        {data?.identity?.number && (
          <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
            <div>
              <p className="text-sm text-coolGrey font-normal mb-2">
                ID Number
              </p>
              <p className="text-sm text-blackDark font-medium">
                {data?.identity?.number}
              </p>
            </div>
            <span className="text-primary text-sm">Edit</span>
          </div>
        )}
        {data?.identity?.image && (
          <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
            <ViewDocument
              file={data?.identity?.image}
              name="Identity"
              showDocument={() =>
                showDocument(data?.identity?.image, "Identity")
              }
            />
            <span className="text-primary text-sm">Edit</span>
          </div>
        )}

        {data?.proofOfAddress?.proof && (
          <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
            <ViewDocument
              file={data?.proofOfAddress?.proof}
              name="Proof Of Address"
              showDocument={() =>
                showDocument(data?.proofOfAddress?.proof, "Proof Of Address")
              }
            />

            <span className="text-primary text-sm">Edit</span>
          </div>
        )}
        {/* <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
            <div>
              <p className="text-sm text-coolGrey font-normal mb-2">Selfie</p>
              <p className="text-sm text-primary font-medium">View Image</p>
            </div>
            <span className="text-primary text-sm">Edit</span>
          </div> */}

        {data?.proofOfAddress?.address && (
          <div className="rounded border border-linkText flex items-center justify-between p-4 my-4">
            <div>
              <p className="text-sm text-coolGrey font-normal mb-2">Address</p>
              <p className="text-sm text-blackDark font-medium">
                {data?.proofOfAddress?.address}
              </p>
            </div>
            <span className="text-primary text-sm">Edit</span>
          </div>
        )}
      </div>
      {/*  */}
      <div>
        <div className="bg-white p-7 rounded border border-btnInactive shadow-sm h-fit mb-4">
          <div className="border-b border-grey pb-3 flex justify-between items-center">
            <p className="text-blackDarkDark font-bold">Services</p>
            <span className="text-primary text-sm">Edit</span>
          </div>
          <div className="flex justify-between items-center my-6">
            <p className="text-sm text-black">Payments</p>
            <span className="inline-flex justify-center items-center text-primary p-1 bg-greenAccent text-sm rounded w-24">
              Approved
            </span>
          </div>

          <div className="flex justify-between items-center my-6">
            <p className="text-sm text-black">Fund Disbursement</p>
            <span className="inline-flex justify-center items-center text-primary p-1 bg-greenAccent text-sm rounded w-24">
              Approved
            </span>
          </div>

          <div className="flex justify-between items-center my-6">
            <p className="text-sm text-black"> Bill Payments</p>
            <span className="inline-flex justify-center items-center text-primary p-1 bg-greenAccent text-sm rounded w-24">
              Approved
            </span>
          </div>

          <div className="flex justify-between items-center my-6">
            <p className="text-sm text-black">Cash Code</p>
            <span className="inline-flex justify-center items-center text-primary p-1 bg-greenAccent text-sm rounded w-24">
              Approved
            </span>
          </div>

          <div className="flex justify-between items-center my-6">
            <p className="text-sm text-black">Virtual Card</p>
            <span className="inline-flex justify-center items-center text-primary p-1 bg-greenAccent text-sm rounded w-24">
              Approved
            </span>
          </div>

          <div className="flex justify-between items-center mt-6 mb-2">
            <p className="text-sm text-black">Account API</p>
            <span className="inline-flex justify-center items-center text-primary p-1 bg-greenAccent text-sm rounded w-24">
              Approved
            </span>
          </div>
        </div>

        <div className="bg-white p-7 rounded border border-btnInactive shadow-sm h-fit">
          <div className="border-b border-grey pb-3 flex justify-between items-center">
            <p className="text-blackDarkDark font-bold">
              Security/Settlement Configuration
            </p>
          </div>
          <div className="flex justify-start items-center gap-4 my-8">
            <Checkbox />
            <p className="text-sm text-black">IP Protection</p>
          </div>

          <div className="flex justify-start items-center gap-4 my-8">
            <Checkbox />
            <p className="text-sm text-black">Two Factor Authentication</p>
          </div>

          <div className="flex justify-start items-center gap-4 my-8">
            <Checkbox />
            <p className="text-sm text-black"> Instant Settlement</p>
          </div>
        </div>
      </div>
      <Modal isShown={["Identity", "Proof of address"].includes(type)}>
        <Document
          onClose={() => {
            setImageToShow("");
            setType("");
          }}
          image={imageToShow}
          type={type}
        />
      </Modal>
    </div>
  );
};

export default UserSettings;
