import { PieChart, Pie, Cell, Legend } from "recharts";

const data = [
  { name: "POS", value: 400 },
  { name: "Bills", value: 250 },
  { name: "Transfers", value: 300 },
  { name: "Services", value: 200 },
];
const COLORS = ["#BAEDBD", "#95A4FC", "#C6C7F8", "#1C1C1C"];

export default function MostUsed() {
  return (
    <div className="bg-white p-5 rounded">
      <div className="flex justify-between items-center">
        <p className="text-blackDark font-bold">Most Used Services</p>
        
      </div>
      <hr className="my-3 border-t-[0.5px] border-t-coolGrey" />

      <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          innerRadius={50}
          outerRadius={80}
          paddingAngle={5}
          dataKey="value"
          cornerRadius={5}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
    </div>
  );
}
