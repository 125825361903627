import Button from "components/Button";
import { AnyObject, object, string } from "yup";
import close from "assets/close.svg";
import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useKYCHandlers } from "../useKycHandlers";
import Input from "components/inputs/Input";

interface RejectProps {
  onClose: () => void;
  data: AnyObject;
}

const UpdateName = ({ onClose, data }: RejectProps) => {
  const { handleUpdateUser, isError, error, loading } = useKYCHandlers(onClose);

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<{ firstName: string; lastName: string; middleName: string }>({
      initialValues: {
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        middleName: data.user.middleName,
      },
      validationSchema: object({
        firstName: string().required("Required"),
        lastName: string().required("Required"),
      }),
      onSubmit: (values) => {
        handleUpdateUser({
          ...values,
          ...{ kycId: data.id, userId: data.user.id },
        });
      },
    });
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-48 rounded"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Update user's name</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b border-btnInactive">
        <Input
          label="First Name"
          id="firstName"
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          placeholder={"Enter first name"}
          error={`${!!errors["firstName"] && !!touched["firstName"]}`}
          errorMessage={errors["firstName"]}
          onBlur={handleBlur}
        />

        {data.user.middleName && (
          <Input
            label="Middle Name"
            id="middleName"
            name="middleName"
            value={values.middleName}
            onChange={handleChange}
            placeholder={"Enter middle name"}
            error={`${!!errors["middleName"] && !!touched["middleName"]}`}
            errorMessage={errors["middleName"]}
            onBlur={handleBlur}
          />
        )}

        <Input
          label="Last Name"
          id="lastName"
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          placeholder={"Enter last name"}
          error={`${!!errors["lastName"] && !!touched["lastName"]}`}
          errorMessage={errors["lastName"]}
          onBlur={handleBlur}
        />
      </div>

      <div className="p-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Decline" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default UpdateName;
