import menu from "assets/menu.svg";
import Pagination from "components/Pagination";
import Status from "components/Status";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { convertDate } from "utils/fx";
import { AnyObject, ContextType } from "utils/types";
import { getUserStatus } from "./Users";
import EmptyTable from "components/EmptyTable";
import { useRef, useState } from "react";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import Modal from "components/Modal";
import BlacklistUser from "./modals/Blacklist";
import { users } from "utils/tableHeaders";

export default function Personal() {
  const navigate = useNavigate();

  const { data, next, prev } = useOutletContext<ContextType>();

  const popRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [idx, setIdx] = useState("");

  useOnClickOutside(popRef, () => {
    !isShown && setIdx("");
  });

  if (data.results.length === 0) {
    return <EmptyTable />;
  }

  return (
    <>
      <div className="py-6 px-12 bg-white border border-btnInactive gap-4 grid grid-cols-[100px_200px_250px_200px_1fr_1fr] items-center">
        {users.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
        <p className="text-sm font-bold text-blackDark text-center">Actions</p>
      </div>

      {data.results?.map((result: AnyObject) => (
        <Link
          key={result.id}
          to={`/users/${result.id}`}
          className="py-6 px-12 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[100px_200px_250px_200px_1fr_1fr] items-center"
        >
          <Status width="w-full" status={getUserStatus(result)} />
          <p className="font-normal text-sm text-blackDark">
            {result?.firstName} {result?.lastName}
          </p>
          <p className="font-normal text-sm text-blackDark break-words">
            {result.email}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {result.dialCode} {result.phoneNumber}
          </p>

          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.createdAt))}
          </p>
          <div
            className="flex items-center justify-center relative"
            onClick={(e) => {
              e.preventDefault();
              setShow(true);
              setIdx(result.id);
            }}
          >
            <img src={menu} alt="menu" />
            {show && idx === result.id && (
              <div
                ref={popRef}
                className="absolute bg-white shadow-md border -right-21 top-1 p-3 rounded flex flex-col gap-3 z-50 w-[180px]"
              >
                <span
                  className="text-sm font-normal text-blackDark cursor-pointer bg-greenAccent p-2 rounded"
                  onClick={() => navigate(`/users/${result.id}/transactions`)}
                >
                  View Transactions
                </span>
                <span
                  className="text-sm font-normal text-danger cursor-pointer"
                  onClick={() => setIsShown(true)}
                >
                  Blacklist
                </span>
              </div>
            )}
          </div>
        </Link>
      ))}
      <Pagination
        data={data as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />
      <Modal isShown={isShown}>
        <BlacklistUser
          user={data?.results?.find((d: AnyObject) => d.id === idx)}
          type="blacklist"
          onClose={() => setIsShown(false)}
        />
      </Modal>
    </>
  );
}
