"use client";

interface ButtonProps {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  outline?: boolean;
  small?: boolean;
  type?: "button" | "submit";
  loading?: boolean;
  variant?: "primary" | "danger" | "secondary" | "neutral" | "other";
  size?: "small" | "medium" | "large";
}
const Button = ({
  label,
  onClick,
  disabled,
  type = "button",
  loading,
  variant = "primary",
  size = "large",
}: ButtonProps) => {
  const variantColor = {
    primary: "text-white bg-primary",
    neutral: "text-blackDark bg-offWhite",
    danger: "text-white bg-danger",
    secondary: "text-amber bg-amberPill",
    other: "text-blackDark bg-[#E6F0EC]"
  };

  const sizes = {
    small: "w-1/5",
    medium: "w-1/2",
    large: "w-full",
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`relative disabled:bg-btnInactive disabled:cursor-not-allowed ${variantColor[variant]} disabled:text-formInactive rounded text-xs font-bold ${sizes[size]} p-3 my-4`}
    >
      {loading ? "loading..." : label}
    </button>
  );
};

export default Button;
