import Status from "components/Status";
import { useNavigate } from "react-router-dom";
import { convertDate, formatNumber } from "utils/fx";
import { AnyObject } from "utils/types";

export default function TransactionHistory({
  transactions,
}: {
  transactions: Array<AnyObject>;
}) {
  const navigate = useNavigate();
  const goToTransactions = () => {
    navigate("/transactions");
  };
  return (
    <div className="bg-white p-5 rounded w-[700px] 2xl:w-[960px]">
      <p className="text-blackDark font-bold">Transaction History</p>
      <hr className="my-3 border-t-[0.5px] border-t-coolGrey" />

      <div className="py-6 px-8 bg-white border-b border-btnInactive gap-4 grid grid-cols-[150px_120px_150px_auto_auto] items-center">
        <p className="text-sm font-bold text-blackDark">Status</p>
        <p className="text-sm font-bold text-blackDark">Date</p>
        <p className="text-sm font-bold text-blackDark">Customer</p>
        <p className="text-sm font-bold text-blackDark">Type</p>
        <p className="text-sm font-bold text-blackDark">Amount</p>
      </div>
      {transactions?.slice(0, 5)?.map((transaction) => (
        <div
          key={transaction.id}
          className="py-4 px-8 bg-white border-b border-btnInactive gap-4 grid grid-cols-[150px_120px_150px_auto_auto] items-center"
        >
          <Status width="w-3/4" status={transaction?.status?.toLowerCase()} />
          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(transaction?.transactionDate))}
          </p>
          <p className="font-normal text-sm text-blackDark break-words">
            {transaction?.source?.accountName}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {transaction?.transactionType}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {formatNumber(transaction?.amount, transaction?.currency ?? "NGN")}
          </p>
        </div>
      ))}

      <div className="flex justify-center">
        <button
          onClick={goToTransactions}
          className="bg-transparent mt-4 text-primary text-sm font-semibold"
        >
          View All
        </button>
      </div>
    </div>
  );
}
