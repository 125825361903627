import home from "assets/home.svg";
import kyc from "assets/kyc.svg";
import users from "assets/users.svg";
import transactions from "assets/transactions.svg";
import card from "assets/card-white.svg";
import bill from "assets/bill.svg";
import bank from "assets/bank.svg";
import metrics from "assets/filter.svg";
import fees from "assets/fees.svg";
import user from "assets/user.svg";
import settings from "assets/settings.svg";
import accounts from "assets/accounts.svg";
import fx from "assets/fx-mgt.svg";
import migration from "assets/migration.svg";

export const RouteLinks = [
  {
    name: "Home",
    path: "/home",
    icon: <img src={home} alt="home" loading="lazy" />,
  },
  {
    name: "KYC",
    path: "/kyc",
    icon: <img src={kyc} alt="kyc" loading="lazy" />,
  },
  {
    name: "Users",
    path: "/users",
    icon: <img src={users} alt="users" loading="lazy" />,
  },

  {
    name: "Accounts",
    path: "/accounts",
    icon: <img src={accounts} alt="accounts" loading="lazy" />,
  },

  {
    name: "Transactions",
    path: "/transactions",
    icon: <img src={transactions} alt="transactions" loading="lazy" />,
  },
  {
    name: "Cards",
    path: "/cards",
    icon: <img src={card} alt="cards" loading="lazy" />,
  },
  {
    name: "FX Management",
    path: "/fx-management",
    icon: <img src={fx} alt="fx management" loading="lazy" />,
  },
  {
    name: "Migration Assessment",
    path: "/migration-assessment",
    icon: <img src={migration} alt="migration" loading="lazy" />,
  },
  {
    name: "Bill Payment",
    path: "/bill-payments",
    icon: <img src={bill} alt="bill" loading="lazy" />,
  },
  {
    name: "Analytics",
    path: "/metrics",
    icon: <img src={metrics} alt="metrics" loading="lazy" />,
  },

  {
    name: "Loans",
    path: "/loans",
    icon: <img src={bank} alt="bank" loading="lazy" />,
  },
  {
    name: "Fees",
    path: "/fees",
    icon: <img src={fees} alt="fees" loading="lazy" />,
  },
  {
    name: "Administrators",
    path: "/administrators",
    icon: <img src={user} alt="user" loading="lazy" />,
  },
  {
    name: "Settings",
    path: "/settings",
    icon: <img src={settings} alt="settings" loading="lazy" />,
  },
]
