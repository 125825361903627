import { startCase } from "lodash";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { AnyObject } from "utils/types";
import { DateFilter, useGetTransactionReport } from "./useHomeHandlers";

const legendColor: AnyObject = {
  "#F9A400": "bg-[#F9A400]",
  "#20D4A5": "bg-[#20D4A5]",
  "#FF4545": "bg-[#FF4545]",
};


export default function StatusReport({dateFrom, dateTo}: DateFilter) {
  
  const { data } = useGetTransactionReport({dateFrom, dateTo});

  const formattedDate = data?.map((d: AnyObject) => ({
    name: new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
    }).format(new Date(d.date)),
    [d.statuses[0]?.status?.toLowerCase()]: d.statuses[0]?.count,
    [d.statuses[1]?.status?.toLowerCase()]: d.statuses[1]?.count,
    [d.statuses[2]?.status?.toLowerCase()]: d.statuses[2]?.count,
  }));

  const renderLegend = (props: AnyObject) => {
    const { payload } = props;
    return (
      <div className="flex gap-8 items-center">
        {payload.map((entry: AnyObject, index: number) => (
          <span className="flex gap-1 items-center" key={`item-${index}`}>
            <span
              className={`inline-flex w-3 h-3 rounded-full ${
                legendColor[entry.color]
              }`}
            />
            <span className="text-sm">{`${startCase(
              entry.value
            )} Transactions`}</span>
          </span>
        ))}
      </div>
    );
  };
  return (
    <div className="bg-white p-5 rounded w-[700px] 2xl:w-[960px]">
      <div className="flex justify-between items-center">
        <p className="text-blackDark font-bold">Transaction Status Report</p>

      </div>
      <hr className="my-3 border-t-[0.5px] border-t-coolGrey" />
      <BarChart
        width={700}
        height={500}
        data={formattedDate}
        margin={{
          top: 60,
          right: 30,
          left: 30,
        }}
      >
        <CartesianGrid stroke="#e9ebee" strokeDasharray="4 4" />
        <XAxis dataKey="name" axisLine={false} />
        <YAxis axisLine={false} />
        <Tooltip cursor={false} />
        <Legend
          wrapperStyle={{
            top: 10,
          }}
          content={renderLegend}
        />
        <Bar
          dataKey="pending"
          stackId="a"
          fill="#F9A400"
          barSize={30}
          radius={[0, 0, 10, 10]}
        />
        <Bar
          dataKey="successful"
          stackId="a"
          fill="#20D4A5"
          barSize={30}
        />
        <Bar
          dataKey="failed"
          stackId="a"
          fill="#FF4545"
          radius={[10, 10, 0, 0]}
          barSize={30}
        />
      </BarChart>
    </div>
  );
}
