import { useNavigate, useParams } from "react-router-dom";
import { useFetchKYC } from "./useKycHandlers";
import { useDocumentTitle } from "hooks/useDocument";
import Loader from "components/Loader";
import PersonalKYCDetails from "./PersonalKYCDetails";
import BusinessKYCDetails from "./BusinessKYCDetails";
import { useAuthContext } from "hooks/useAuthContext";
import { useEffect } from "react";

export default function KYCDetails() {
  useDocumentTitle("KYC Details");

  const { id } = useParams();
  const {user } = useAuthContext()
  const navigate = useNavigate();

  const { data, isLoading } = useFetchKYC(id ?? "");

  const canViewKYC = user?.permissions?.includes('view:kyc')
  useEffect(() => {
    if(!canViewKYC && !isLoading) {
      navigate('/home')
    }
  }, [canViewKYC, isLoading, navigate])

  if (isLoading) return <Loader />;

  if (data?.userType === "business") {
    return <BusinessKYCDetails data={data} />;
  }

  return <PersonalKYCDetails data={data} />;
}
