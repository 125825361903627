import { Link, useOutletContext } from "react-router-dom";
import { convertDate } from "utils/fx";
import { AnyObject, ContextType } from "utils/types";
import Pagination from "components/Pagination";
import EmptyTable from "components/EmptyTable";
import Modal from "components/Modal";
import BlacklistUser from "./modals/Blacklist";
import { useState } from "react";
import { blacklistedUsers } from "utils/tableHeaders";

export default function Blacklisted() {
  const { data, next, prev } = useOutletContext<ContextType>();
  const [isShown, setIsShown] = useState(false);
  const [idx, setIdx] = useState("");

  const onWhiteList = (id: string) => {
    setIdx(id);
    setIsShown(true);
  };

  if (data.results.length === 0) {
    return <EmptyTable />;
  }
  return (
    <>
      <div className="py-6 px-12 bg-white border border-btnInactive gap-4 grid grid-cols-[1fr_1fr_1fr_1fr_50px] items-center">
        {blacklistedUsers.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
        <p className="text-sm font-bold text-blackDark"></p>
      </div>

      {data.results?.map((result: AnyObject) => (
        <Link
          key={result.id}
          to={`/users/${result.id}`}
          className="py-6 px-12 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[1fr_1fr_1fr_1fr_50px] items-center"
        >
          <p className="font-normal text-sm text-blackDark">
            {result.firstName} {result.lastName}
          </p>
          <p className="font-normal text-sm text-blackDark break-words">
            {result.email}
          </p>
          <p className="font-normal text-sm text-blackDark">{result.reason}</p>

          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.createdAt))}
          </p>
          <p
            className="text-primary text-sm font-medium"
            onClick={(e) => {
              e.preventDefault();
              onWhiteList(result.id);
            }}
          >
            Whitelist
          </p>
        </Link>
      ))}
      <Pagination
        data={data as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />

      <Modal isShown={isShown}>
        <BlacklistUser
          user={data?.results?.find((d: AnyObject) => d.id === idx)}
          type="whitelist"
          onClose={() => setIsShown(false)}
        />
      </Modal>
    </>
  );
}
