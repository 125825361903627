import { startCase } from "lodash";
import { useParams } from "react-router-dom";
import { convertDate, formatNumber } from "utils/fx";
import { AnyObject,} from "utils/types";
import { useFetchUserCards } from "./useUsersHandlers";
import Loader from "components/Loader";
import EmptyTable from "components/EmptyTable";

const UserCard = () => {
  const param = useParams();

  const { data, isLoading } = useFetchUserCards(param.id ?? "");

  if (isLoading) return <Loader />;
  if(data.length === 0) return <EmptyTable />

  return data?.map((item: AnyObject) => (
    <div
      key={item.walletId}
      className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm"
    >
      <div className="border-b border-grey pb-3 mb-4">
        <p className="text-blackDark text-[15px] font-bold flex items-center gap-3">
            Account Balance:
          <span className="text-primary text-lg font-medium">
            {formatNumber(
              item?.balance/100,
              item?.currency ?? "NGN"
            )}
          </span>
          <span className="bg-greenAccent text-primary  inline-flex py-1 px-4 rounded text-sm items-center justify-center">
            {item.type}
        </span>
        </p>
       
      </div>

      <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
        <div>
          <p className="text-coolGrey text-sm mb-3">Card Name</p>
          <p className="text-blackDark text-sm font-medium">
            {item?.name}
          </p>
        </div>

        <div>
          <p className="text-coolGrey text-sm mb-3">Card Number</p>
          <p className="text-blackDark text-sm font-medium">
            {item?.cardNumber}
          </p>
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">Expiry Date</p>
          <p className="text-blackDark text-sm font-medium">{item?.expirationDate}</p>
        </div>

        <div>
          <p className="text-coolGrey text-sm mb-3">CVV</p>
          <p className="text-blackDark text-sm font-medium">
            {startCase(item?.cvv)}
          </p>
        </div>
        
        <div>
          <p className="text-coolGrey text-sm mb-3">Card Service</p>
          <p className="text-blackDark text-sm font-medium">
            {" "}
            
          </p>
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">Billing Address</p>
          <p className="text-blackDark text-sm font-medium">{item?.billingAddress?.street}</p>
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">Zip Code</p>
          <p className="text-blackDark text-sm font-medium">{item?.billingAddress?.postalCode}</p>
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">City</p>
          <p className="text-blackDark text-sm font-medium">{item?.billingAddress?.city}</p>
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">State</p>
          <p className="text-blackDark text-sm font-medium">{item?.billingAddress?.state}</p>
        </div>
        <div>
          <p className="text-coolGrey text-sm mb-3">Date Created</p>
          <p className="text-blackDark text-sm font-medium">{convertDate(item.createdAt)}</p>
        </div>
      </div>
    </div>
  ));
};

export default UserCard;
