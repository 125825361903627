import Button from "components/Button";
import Input from "components/inputs/Input";
import filter from "assets/filter.svg";
import back from "assets/back.svg";
import { useState } from "react";
import { useDocumentTitle } from "hooks/useDocument";
import { AnyObject } from "utils/types";
import Pagination from "components/Pagination";
import AccessDenied from "components/AccessDenied";
import {
  convertDate,
  convertTime,
  copyToClipboard,
  formatNumber,
  formatReference,
} from "utils/fx";
import copy from "assets/copy-grey.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
import EmptyTable from "components/EmptyTable";
import { useFetchUserTransactions } from "./useUsersHandlers";
import { useDebounce } from "hooks/useDebounce";

export default function UserTransactions({ showTitle = true }) {
  useDocumentTitle("Transactions");

  const navigate = useNavigate();

  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { data, error, loading } = useFetchUserTransactions(
    page,
    id ?? "",
    useDebounce(searchTerm)
  );

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  if (loading) return <Loader />;

  if (data?.results?.length === 0) {
    return <EmptyTable />;
  }

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }
  return (
    <>
      {showTitle && (
        <div
          className="pt-2 mb-4 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back" />
          Users
        </div>
      )}
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">
          All Transactions - {data?.totalResults ?? 0}
        </p>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div className="flex gap-2 items-center mt-2">
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter filter"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          <div className="flex gap-2 justify-end ml-36">
            <Button size="medium" label="Download CSV" />
          </div>
        </div>
      </div>
      <div className="py-6 px-8 bg-white border border-btnInactive gap-4 grid grid-cols-[200px_120px_100px_50px_200px_100px_200px] items-center">
        <p className="text-sm font-bold text-blackDark">Transaction Ref</p>
        <p className="text-sm font-bold text-blackDark">Amount</p>
        <p className="text-sm font-bold text-blackDark">Fee</p>
        <p className="text-sm font-bold text-blackDark">Type</p>
        <p className="text-sm font-bold text-blackDark">Beneficiary</p>
        <p className="text-sm font-bold text-blackDark">Date</p>
        <p className="text-sm font-bold text-blackDark">Description</p>
      </div>

      {data?.results?.map((result: AnyObject) => (
        <Link
          to={`/transactions/${result.id}`}
          key={result.id}
          className="py-2 px-8 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[200px_120px_100px_50px_200px_100px_200px] items-center"
        >
          <div
            className="cursor-pointer flex gap-0.5 items-baseline"
            onClick={(e) => {
              e.preventDefault();
              if (result.author) {
                navigate(`/users/${result.author.id}`);
              }
            }}
          >
            <span
              className={`flex w-2.5 h-2.5 rounded-full bg-${
                result.status === "SUCCESSFUL" ? "primary" : "danger"
              }`}
            />{" "}
            <span className="font-normal text-sm text-blackDark inline-flex gap-0.5">
              <span className="truncate">
                {formatReference(result.transactionRef)}
              </span>
              <img
                src={copy}
                alt="copy"
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  copyToClipboard(result.transactionRef);
                }}
              />
            </span>
          </div>
          <p className="font-semibold text-sm text-blackDark">
            {formatNumber(result.amount, result.currency)}
          </p>

          <p className="font-semibold text-sm text-blackDark">
            {formatNumber(result.feeAmount, result.currency)}
          </p>

          <p className="font-semibold text-sm text-blackDark">
            {result.transactionType}{" "}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {result.beneficiary?.accountName ?? result.beneficiary?.accountNo}{" "}
          </p>

          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.transactionDate))} <br />{" "}
            {convertTime(new Date(result.transactionDate))}
          </p>

          <p className="font-normal text-sm text-blackDark break-words">
            {result.description}{" "}
          </p>
        </Link>
      )) || <EmptyTable />}

      {data?.results?.length > 0 && (
        <Pagination data={data} prev={prev} next={next} />
      )}
    </>
  );
}
