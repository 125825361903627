import website from "assets/website.svg";
import mobile from "assets/mobile.svg";
import { AnyObject } from "utils/types";
import { abbreviateNumber } from "utils/fx";

const totalLogins = (arr: Array<AnyObject>) =>
  arr?.reduce((acc, cum) => acc + cum.count, 0);
const percentCount = (arr: Array<AnyObject>, count: number) => {
  const fraction = count / totalLogins(arr);
  const percentValue = `${(fraction * 100).toFixed(2)}%`;
  return { fraction, percentValue };
};

export default function DeviceType({
  loginDeviceTypes,
}: {
  loginDeviceTypes: Array<AnyObject>;
}) {
  return (
    <div className="bg-white p-5 rounded">
      <div className="flex justify-between items-center">
        <p className="text-blackDark font-bold">Device Type</p>
      </div>
      <hr className="my-3 border-t-[0.5px] border-t-coolGrey" />
      <div className="flex flex-col justify-center items-center">
        <p className="text-lg text-blackDark mb-2 font-bold">
          {abbreviateNumber(
            loginDeviceTypes?.reduce((acc, cum) => acc + cum.count, 0),
            1
          )}
        </p>
        <small className="text-coolGrey font-bold">
          Device Logins
        </small>
      </div>
      {/* Chart goes here */}
      {loginDeviceTypes?.map((type) => (
        <div
          key={type.deviceType}
          className="grid grid-cols-[200px_auto_auto_auto] items-center my-3"
        >
          <div className="flex gap-3 items-center">
            <span className="inline-flex items-center justify-center bg-[#DEF9F2] w-8 h-8 rounded-full">
              <img
                src={type.deviceType === "Mobile app" ? mobile : website}
                alt="website"
              />
            </span>
            <p className="font-semibold text-blackDark text-sm">
              {type.deviceType}{" "}
            </p>
          </div>

          <div className="bg-[#e5e2ee] w-32 h-1.5 rounded-lg relative">
            <span
              className="absolute bg-primary h-1.5 rounded-lg"
              style={{
                width: `${
                  128 * percentCount(loginDeviceTypes, type.count).fraction
                }px`,
              }}
            />
          </div>
          <div className="flex gap-2">
            <p className="text-primary text-sm">
              {percentCount(loginDeviceTypes, type.count).percentValue}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
