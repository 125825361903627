import close from "assets/close.svg";
import Button from "components/Button";
import Select from "components/inputs/Select";
import { useFormik } from "formik";
import { object, string } from "yup";

import { useMigrationActions } from "../useMigrationHandlers";
import { AssignType } from "../Migration";
import { useFetchAdmins } from "pages/authenticated/admin/useAdminHandlers";
import { AnyObject } from "utils/types";

interface AssignProps {
  onClose: () => void;
  type: AssignType;
  lead: AnyObject
}

export interface AssignValues {
  assignedType: string;
  staff: string;
  leadId: string;
}

const Assign = ({ onClose, type, lead }: AssignProps) => {
  const { handleAssign , loading } = useMigrationActions(onClose);

  const { data } = useFetchAdmins(1);
  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<AssignValues>({
      initialValues: {
        assignedType: "",
        staff: "",
        leadId: lead.id
      },
      validationSchema: object({
        assignedType: string().required("Department must be selected"),
        staff: string().required("A staff must be selected"),
      }),
      onSubmit: (values) => {
        const user = data?.results?.find((item: AnyObject) => item.id === values.staff)
        const payload = {
          assignType: values.assignedType,
          assignedTo: {
            userName: `${user.firstName} ${user.lastName}`,
            userId: values.staff,
            email: user.email
          },
          leadId: values.leadId

        }
        handleAssign(payload)
      },
    });

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto my-40 rounded overflow-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Assign to</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>

      <div className="p-4 border-b  border-btnInactive">
        <Select
          label="Select Department"
          id="assignedType"
          name="assignedType"
          value={values.assignedType}
          onChange={handleChange}
          placeholder={"Select department"}
          error={`${!!errors["assignedType"] && !!touched["assignedType"]}`}
          errorMessage={errors["assignedType"]}
          data={[
            {
              label: "Account Manager",
              value: "accountManager",
            },
            {
              label: "Customer Success",
              value: "customerSuccess",
            },
          ]}
          onBlur={handleBlur}
        />

        <Select
          label="Select Staff"
          id="staff"
          name="staff"
          value={values.staff}
          onChange={handleChange}
          placeholder={"Select staff"}
          error={`${!!errors["staff"] && !!touched["staff"]}`}
          errorMessage={errors["staff"]}
          data={data?.results?.map((admin: AnyObject) => ({
            label: `${admin.firstName} ${admin.lastName}`,
            value: admin.id,
          }))}
          onBlur={handleBlur}
        />
      </div>

      <div className="p-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Save" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default Assign;
