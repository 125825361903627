import Button from "components/Button";
import Tier from "components/Tier";
import { AnyObject } from "utils/types";
import close from "assets/close.svg";
import { useEffect } from "react";
import { useKYCHandlers } from "../useKycHandlers";
import { toast } from "react-hot-toast";

interface ApproveProps {
  onClose: () => void;
  data: AnyObject;
}

const ApproveKYC = ({ onClose, data }: ApproveProps) => {


  const {handleApproveKYC, isError, error, loading} = useKYCHandlers(onClose)

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const onApprove = () => {
    const payload = {approved: true, requestId: data?.id }
    handleApproveKYC(payload)
  }

  return (
    <div className="bg-white w-[450px] m-auto mt-48 rounded">
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Approve Tier Upgrade</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b border-btnInactive">
        <p className="text-blackDark text-center font-medium text-lg">
          Are you sure you want approve from
        </p>
        <div className="flex gap-4 items-center justify-center">
          <Tier status={data?.currentAccountTier} />
          <span className="text-blackDark text-center font-medium text-lg">
            to
          </span>
          <Tier status={data?.requestedTier} />?
        </div>
      </div>

      <div className="px-4 py-2 flex gap-4 justify-between items-center">
        <Button
          variant="neutral"
          label="No, Cancel"
          onClick={onClose}
          size="medium"
        />
        <Button label="Yes, Approve" onClick={onApprove} size="medium" loading={loading} />
      </div>
    </div>
  );
};

export default ApproveKYC;
