import Input from "components/inputs/Input";
import filter from "assets/filter.svg";
import { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDocumentTitle } from "hooks/useDocument";
import Loader from "components/Loader";
import { AnyObject, ContextType } from "utils/types";
import AccessDenied from "components/AccessDenied";
import { useFetchTransactions } from "./useTransactionHandlers";
import CsvButton from "components/CsvButton";
import { crossBorder, localTransactions } from "utils/tableHeaders";
import { convertDate, convertTime } from "utils/fx";
import { useAuthContext } from "hooks/useAuthContext";
import { useDebounce } from "hooks/useDebounce";

const tabs = ["local", "cross-border"];

export type TransferType = "INTER-BANK,INTRA-BANK" | "INTERNATIONAL" | "ALL";

const tabCategory: { [key: string]: TransferType } = {
  "cross-border": "INTERNATIONAL",
};

type tabType = (typeof tabs)[number];
export default function TransactionsLayout() {
  const param = useParams<Record<"*", tabType>>();
  const location = useLocation();
  const [tab, setTab] = useState<tabType>("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { user } = useAuthContext();
  const navigate = useNavigate();

  useDocumentTitle(`Transactions - ${tab || "Local"}`);

  const { data, error, loading } = useFetchTransactions(
    tabCategory[tab] || "INTER-BANK,INTRA-BANK",
    page,
    useDebounce(searchTerm)
  );

  const canViewTransactions = user?.permissions?.includes(
    "view:transactions:all"
  );
  useEffect(() => {
    if (!canViewTransactions && !loading) {
      navigate("/home");
    }
  }, [canViewTransactions, loading, navigate]);

  useEffect(() => {
    if (param["*"]) {
      if (tabs.includes(param["*"].split("/")[1])) {
        setTab(param["*"].split("/")[1]);
      } else {
        setTab("");
      }
    }
  }, [param]);

  useEffect(() => {
    setPage(1);
  }, [location.pathname]);


  if (loading) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  const csvData = [tab === "" ? localTransactions : crossBorder];

  const getCSVData = () => {
    if (data?.results?.length === 0) {
      return [];
    }

    if (tab === "") {
      for (let i = 0; i < data?.results?.length; i++) {
        let arr = [];
        arr.push(data?.results[i]?.source.accountName);
        arr.push(`${data?.results[i]?.currency} ${data?.results[i]?.amount}`);
        arr.push(data?.results[i]?.transactionRef);
        arr.push(`${data?.results[i]?.transactionType}`);

        arr.push(
          `${
            data?.results[i]?.beneficiary?.accountName ??
            data?.results[i]?.beneficiary?.accountNo
          }`
        );

        arr.push(
          `${convertDate(new Date(data?.results[i]?.transactionDate))} /
        ${convertTime(new Date(data?.results[i]?.transactionDate))}`
        );
        arr.push(`${data?.results[i]?.description}`);

        csvData.push(arr);
      }
    } else {
      for (let i = 0; i < data?.results?.length; i++) {
        let arr = [];
        arr.push(data?.results[i]?.source.accountName);
        arr.push(`${data?.results[i]?.currency} ${data?.results[i]?.amount}`);
        arr.push(data?.results[i]?.transactionRef);
        arr.push(`${data?.results[i]?.transactionType}`);

        arr.push(
          `${
            data?.results[i]?.beneficiary?.accountName ??
            data?.results[i]?.beneficiary?.accountNo
          }`
        );

        arr.push(
          `${convertDate(new Date(data?.results[i]?.transactionDate))}/
        ${convertTime(new Date(data?.results[i]?.transactionDate))}`
        );
        arr.push(`${data?.results[i]?.status}`);

        csvData.push(arr);
      }
    }
    return csvData;
  };

  return (
    <>
      <div className="pt-2 flex justify-start items-center gap-8 ml-16 font-medium">
        <Link
          to=""
          role="tab"
          className={`${
            tab === ""
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          } pb-4 cursor-pointer`}
        >
          Local Transactions
        </Link>
        <Link
          to="cross-border"
          role="tab"
          className={`pb-4 cursor-pointer ${
            tab === "cross-border"
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          }`}
        >
          Cross Border
        </Link>
      </div>
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">
          All Transactions - {data?.totalResults}
        </p>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div className="flex gap-2 items-center mt-2">
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Account Name or Account Number"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          <div className="flex gap-2 justify-end ml-36">
            <CsvButton
              data={getCSVData()}
              filename={
                tab === ""
                  ? `All_accounts_${page}.csv`
                  : `${tab}_accounts_${page}.csv`
              }
            />
          </div>
        </div>
      </div>
      <Outlet context={{ data, next, prev } satisfies ContextType} />
    </>
  );
}
