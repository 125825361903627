import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import back from "assets/back.svg";
import { useFetchMigration } from "./useMigrationHandlers";
import Loader from "components/Loader";
import { AnyObject, ContextType, StringArrayToUnion } from "utils/types";
import AccessDenied from "components/AccessDenied";
import { convertDate } from "utils/fx";
import Button from "components/Button";
import Timeline from "components/Timeline";
import { useAuthContext } from "hooks/useAuthContext";
import ArrowDropdown from "components/ArrowDropdown";
import Modal from "components/Modal";
import Assign from "./modal/Assign";
import Status from "./modal/Status";
import Consultant from "./modal/Consultant";
import Recommendation from "./modal/Recommendations";
import Tags from "./modal/Tags";

const tabs = ["details", "comments"];
const modals = [
  "status",
  "assign",
  "consultant",
  "tags",
  "recommendation",
] as const;

type tabType = (typeof tabs)[number];

export type ModalType = StringArrayToUnion<typeof modals>;
export type AssignType = Exclude<
  ModalType,
  "status" | "tags" | "recommendation"
>;

export default function Migration() {
  const param = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState<tabType>("details");
  const [modal, setModal] = useState<ModalType | "">("");

  const toggleDropdown = (val: ModalType) => {
    setModal(val);
  };

  const closeModal = () => setModal("");

  const { user } = useAuthContext();

  const { data, error, isLoading } = useFetchMigration(param.id ?? "");
  const canViewMigrations = user?.permissions?.includes(
    "view:migrationAssessment"
  );
  useEffect(() => {
    if (!canViewMigrations && !isLoading) {
      navigate("/home");
    }
  }, [canViewMigrations, isLoading, navigate]);

  useEffect(() => {
    if (param["*"]) {
      if (tabs.includes(param["*"].split("/")[2])) {
        setTab(param["*"].split("/")[2]);
      } else {
        setTab("details");
      }
    }
  }, [param]);

  if (isLoading) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }
  return (
    <>
      <div className="flex items-center justify-between">
        <div
          role="button"
          className="pt-2 mb-4 cursor-pointer flex justify-between items-center gap-3 text-greyDark font-bold"
          onClick={() => navigate(-1)}
        >
          <img src={back} alt="back" />
          Migrations
        </div>
        <div className="flex gap-2 justify-end ml-24">
          <Button
            size="large"
            label="Give Recommendation"
            type="button"
            onClick={() => toggleDropdown("recommendation")}
          />
        </div>
      </div>
      {/*  */}
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <div className="pb-3">
          <p className="text-coolGrey text-sm font-normal flex items-center gap-3">
            Timeline
          </p>
        </div>

        <Timeline data={data?.timeline} />
      </div>
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <div className="grid grid-cols-userCard gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">Status</p>
            <div className="flex gap-2 items-center">
              <p className="text-blackDark text-sm font-medium">
                {data?.status}
              </p>
              <ArrowDropdown
                type="status"
                modal={modal}
                toggleDropdown={toggleDropdown}
              />
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Assigned to</p>
            <div className="flex gap-2 items-center">
              <p className="text-blackDark text-sm font-medium">
                {data?.assignedTo?.userName ?? "N/A"}
              </p>
              <ArrowDropdown
                type="assign"
                modal={modal}
                toggleDropdown={toggleDropdown}
              />
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Account Manager</p>
            <p className="text-blackDark text-sm font-medium">{data?.accountManager?.userName ?? "N/A"}</p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Customer Success</p>
            <p className="text-blackDark text-sm font-medium">{data?.customerSuccess?.userName ?? "N/A"}</p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Consultant</p>
            <div className="flex gap-2 items-center">
              <p className="text-blackDark text-sm font-medium">{data?.consultant?.userName ?? "N/A"}</p>
              <ArrowDropdown
                type="consultant"
                modal={modal}
                toggleDropdown={toggleDropdown}
              />
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Tags</p>
            <div className="flex gap-2 items-center">
              <p className="text-blackDark text-sm font-medium">{data?.tags?.toString()}</p>
              <ArrowDropdown
                type="tags"
                modal={modal}
                toggleDropdown={toggleDropdown}
              />
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Recommendations</p>
            {data?.recommendations?.map((rec: AnyObject) => (
              <div key={rec._id} className="flex gap-2 items-center">
                <p className="text-blackDark text-sm font-medium">
                  {rec.country}
                </p>
                -
                <p className="text-blackDark text-sm font-medium">
                  {rec.pathway}
                </p>
              </div>
            ))}
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Date Created</p>
            <p className="text-blackDark text-sm font-medium">
              {convertDate(new Date(data?.createdAt))}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">View Form</p>
            <p className="text-blackDark text-sm font-medium">  
            <Link
              to={`https://blumefy.com/migration?hash=${data?.hash}`}
            >
              Click here
            </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="pb-4 flex justify-start items-center gap-8 ml-16 font-medium">
        <Link
          to=""
          role="tab"
          className={`pb-1 cursor-pointer ${
            tab === "details"
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          }`}
        >
          Details
        </Link>

        {/* <Link
          to="website-activity"
          role="tab"
          className={`${
            tab === "website-activity"
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          } pb-1 cursor-pointer`}
        >
          Website Activity
        </Link> */}
        <Link
          to="comments"
          role="tab"
          className={`${
            tab === "comments"
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          } pb-1 cursor-pointer`}
        >
          Comments
        </Link>
      </div>

      <Outlet
        context={
          {
            data: { ...data },
            next: () => {},
            prev: () => {},
          } satisfies ContextType
        }
      />
      <Modal isShown={modals.includes(modal as ModalType)}>
        {modal === "assign" && (
          <Assign onClose={closeModal} type={modal as AssignType} lead={data} />
        )}
        {modal === "status" && <Status data={data} onClose={closeModal} />}
        {modal === "consultant" && (
          <Consultant onClose={closeModal} data={data} />
        )}
        {modal === "recommendation" && (
          <Recommendation onClose={closeModal} data={data} />
        )}
        {modal === 'tags' && <Tags onClose={closeModal} data={data} />}
      </Modal>
    </>
  );
}
