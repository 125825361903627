import Button from "components/Button";
import { AnyObject } from "utils/types";
import close from "assets/close.svg";
import { useEffect } from "react";
import { useKYCHandlers } from "../useKycHandlers";
import { toast } from "react-hot-toast";
import { startCase } from "lodash";

interface ActionProps {
  onClose: () => void;
  data: AnyObject;
  action: "approve" | "reject"
}

const BusinessActions = ({ onClose, data, action }: ActionProps) => {
  const { handleUpdateBusiness, isError, error, loading } = useKYCHandlers(onClose);

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const onUpdateBusiness = () => {
    const payload = { verified: action === "approve", id: data?.business };
    handleUpdateBusiness(payload);
  };

  return (
    <div className="bg-white w-[450px] m-auto mt-48 rounded">
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">{startCase(action)} Business</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b border-btnInactive">
        <p className="text-blackDark text-center font-medium text-lg">
          Are you sure you want {action} this business?
        </p>
      </div>

      <div className="px-4 py-2 flex gap-4 justify-between items-center">
        <Button
          variant="neutral"
          label="No, Cancel"
          onClick={onClose}
          size="medium"
        />
        <Button
          label={`Yes, ${startCase(action)}`}
          onClick={onUpdateBusiness}
          size="medium"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default BusinessActions;
