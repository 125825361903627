import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";

const usersUrl = (page: number, type: string, filter?: string) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    limit: 30,
    sortBy: "createdAt:desc",
  });
  if (type === "all") {
    return `users/v1?${query}`;
  } else if (type === "blacklisted") {
    return `users/v1?${query}&blackListed=true`;
  } else {
    return `users/v1?${query}&userType=${type}`;
  }
};

// Get all Users
const getUsers = async (page: number, type: string, filter?: string) => {
  const res = await api.get(usersUrl(page, type, filter));
  return res.data.data;
};
export const useFetchUsers = (page: number, type: string, filter?: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["users", page, type, filter],
    () => getUsers(page, type, filter),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get single User
const getUser = async (id: string) => {
  const res = await api.get(`users/v1/${id}`);
  return res.data.data;
};
export const useFetchUser = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["user", id],
    () => getUser(id),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

//   User transactions
const getTransactions = async (
  page: number,
  userId: string,
  filter?: string,
) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    limit: 30,
    user: userId,
    sortBy: "transactionDate:desc",
  });
  const res = await api.get(`transactions/v1?${query}`);
  return res.data.data.transactions;
};
export const useFetchUserTransactions = (
  page: number,
  userId: string,
  filter?: string,
) => {
  const { data, isLoading, isError, error } = useQuery(
    ["userTransactions", page, filter],
    () => getTransactions(page, userId, filter),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    data,
    loading: isLoading,
    isError,
    error,
  };
};

//   Sub Account transactions
const getSubAccountTransactions = async (
  page: number,
  accountNumber: string,
  filter?: string,
) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    limit: 30,
    accountNumber,
    sortBy: "transactionDate:desc",
  });
  const res = await api.get(`transactions/v1?${query}`);
  return res.data.data.transactions;
};
export const useFetchSubAccountTransactions = (
  page: number,
  accountNumber: string,
  filter?: string,
) => {
  const { data, isLoading, isError, error } = useQuery(
    ["subAccountTransactions", page, filter],
    () => getSubAccountTransactions(page, accountNumber, filter),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    data,
    loading: isLoading,
    isError,
    error,
  };
};

// Get user wallet
const getWallet = async (id: string) => {
  const res = await api.get(`wallets/v1/user/${id}`);
  return res.data.data.wallets ?? [];
};
export const useFetchUserWallet = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["userWallet"],
    () => getWallet(id),
    { retry: false, refetchOnWindowFocus: false, enabled: !!id }
  );
  return {
    wallet: data,
    isLoading,
    isError,
    error,
  };
};

// Get user kyc
const getUserKYC = async (id: string) => {
  const res = await api.get(`users/v1/kyc/${id}`);
  return res.data.data.kyc;
};
export const useFetchUserKYC = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["userKYC", id],
    () => getUserKYC(id),
    { retry: false, refetchOnWindowFocus: false, enabled: !!id }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get user wallets
const getUserWallets = async (id: string) => {
  const res = await api.get(`wallets/v1/all?userId=${id}`);
  return res.data.data;
};
export const useFetchUserWallets = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["userWallets", id],
    () => getUserWallets(id),
    { retry: false, refetchOnWindowFocus: false, enabled: !!id }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get user cards
const getUserCards = async (id: string) => {
  const res = await api.get(`cards/v1?user=${id}`);
  return res.data.data.cards;
};
export const useFetchUserCards = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["userCards", id],
    () => getUserCards(id),
    { retry: false, refetchOnWindowFocus: false, enabled: !!id }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get user sub-accounts summary
const getUserSubAccountSummary = async (id: string) => {
  const res = await api.get(`wallets/v1/${id}/summary`);
  return res.data.data;
};
export const useFetchUserSubAccountSummary = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["userSubAccountSummary", id],
    () => getUserSubAccountSummary(id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get user sub-accounts
const getUserSubAccounts = async (
  id: string,
  page: number,
  searchTerm: string
) => {
  const query = buildQuery({ userId: id, searchTerm, page, pageSize:30 });
  const res = await api.get(`wallets/v1/sub-accounts/user?${query}`);
  return res.data.data;
};
export const useFetchUserSubAccounts = (
  id: string,
  page: number,
  searchTerm: string
) => {
  const { data, isLoading, isError, error } = useQuery(
    ["userSubAccounts", id, page, searchTerm],
    () => getUserSubAccounts(id, page, searchTerm),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Action handlers
export const useUserHandlers = (onClose?: () => void) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    ({ id, ...rest }: { blackListed: boolean; id: string }) => {
      return api.patch(`users/v1/${id}`, rest);
    },
    {
      onSuccess: async (res, { blackListed }) => {
        if (blackListed) {
          navigate(`/users/blacklisted`);
          toast.success(`User successfully blacklisted`);
        } else {
          navigate(`/users/`);
          toast.success(`User successfully whitelisted`);
        }
      },
    }
  );

  const {
    mutate: updateMutate,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
  } = useMutation(
    ({
      id,
      ...rest
    }: {
      phoneNumber?: string;
      dateOfBirth?: string;
      id: string;
    }) => {
      return api.patch(`users/v1/${id}`, rest);
    },
    {
      onSuccess: async (res, { id }) => {
        onClose?.();
        queryClient.invalidateQueries(["user", id]);
        toast.success(`User successfully updated`);
      },
    }
  );

  const handleUserAction = (data: { blackListed: boolean; id: string }) => {
    mutate(data as { blackListed: boolean; id: string });
  };

  const handleUpdateUser = (data: {
    phoneNumber?: string;
    dateOfBirth?: string;
    id: string;
  }) => {
    updateMutate(
      data as { phoneNumber?: string; dateOfBirth?: string; id: string }
    );
  };

  return {
    handleUserAction,
    handleUpdateUser,
    loading: isLoading || isLoadingUpdate,
    isError: isError || isErrorUpdate,
    error: error || errorUpdate,
  };
};
