import close from "assets/close.svg";
import Button from "components/Button";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import { useFormik } from "formik";
import { number, object, string } from "yup";

import { useFxActions } from "../useFxHandlers";
import { currencies } from "utils/fx";
import { AnyObject } from "utils/types";

interface EditExchangeProps {
  onClose: () => void;
  data: AnyObject;
}

export interface EditExchangeValues {
  fromCurrency: string;
  toCurrency: string;
  rate: string;
}
const EditExchange = ({ onClose, data }: EditExchangeProps) => {
  const { handleUpdateExchange, loading } = useFxActions(onClose);

  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<EditExchangeValues>({
      initialValues: {
        fromCurrency: data.fromCurrency,
        toCurrency: data.toCurrency,
        rate: data.rate,
      },
      validationSchema: object({
        fromCurrency: string().required("A currency must be selected"),
        toCurrency: string().required("A currency must be selected"),

        rate: number()
          .min(0.0001, "A number greater than zero is required")
          .required("A number is required"),
      }),
      onSubmit: (values) => {
        handleUpdateExchange(values);
      },
    });

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-10 rounded overflow-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Edit Currency</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>

      <div className="p-4 border-b  border-btnInactive">
        <Select
          label="From Currency"
          id="fromCurrency"
          name="fromCurrency"
          value={values.fromCurrency}
          onChange={handleChange}
          placeholder={"Select currency"}
          error={`${!!errors["fromCurrency"] && !!touched["fromCurrency"]}`}
          errorMessage={errors["fromCurrency"]}
          data={currencies}
          onBlur={handleBlur}
        />

        <Select
          label="To Currency"
          id="toCurrency"
          name="toCurrency"
          value={values.toCurrency}
          onChange={handleChange}
          placeholder={"Select currency"}
          error={`${!!errors["toCurrency"] && !!touched["toCurrency"]}`}
          errorMessage={errors["toCurrency"]}
          data={currencies}
          onBlur={handleBlur}
        />
        <Input
          type="number"
          label="Rate"
          id="rate"
          name="rate"
          value={values.rate}
          onChange={handleChange}
          placeholder={"Enter rate"}
          error={`${!!errors["rate"] && !!touched["rate"]}`}
          errorMessage={errors["rate"]}
          onBlur={handleBlur}
        />
      </div>

      <div className="p-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Save" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default EditExchange;
