import { AnyObject, ContextType } from "utils/types";
import Pagination from "components/Pagination";
import {
  convertDate,
  convertTime,
  copyToClipboard,
  formatReference,
} from "utils/fx";
import copy from "assets/copy-grey.svg";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import EmptyTable from "components/EmptyTable";
import Status from "components/Status";
import { crossBorder } from "utils/tableHeaders";

export default function CrossBorderTransactions() {
  const navigate = useNavigate();
  const { data, next, prev } = useOutletContext<ContextType>();

  if (data?.results.length === 0) {
    return <EmptyTable message="No Cross Border Transactions yet." />;
  }

  return (
    <>
      <div className="py-6 px-8 bg-white border border-btnInactive gap-4 grid grid-cols-[1.2fr_150px_180px_50px_150px_120px_120px] items-center">
        {crossBorder.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
      </div>

      {data?.results?.map((result: AnyObject) => (
        <Link
          to={`/transactions/${result.id}`}
          key={result.id}
          className="py-2 px-8 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[1.2fr_150px_180px_50px_150px_120px_120px] items-center"
        >
          <div
            className="cursor-pointer flex gap-2 items-baseline"
            onClick={(e) => {
              e.preventDefault();
              if (result.author) {
                navigate(`/users/${result.author.id}`);
              }
            }}
          >
            <span
              className={`flex w-2.5 h-2.5 rounded-full bg-${
                result.status === "SUCCESSFUL" ? "primary" : "danger"
              }`}
            />{" "}
            <span className="font-normal text-sm text-primary">
              {" "}
              {result.source.accountName}
            </span>
          </div>
          <p className="font-semibold text-sm text-blackDark">
            {result.currency} {result.amount}
          </p>

          <span className="font-normal text-sm text-blackDark inline-flex gap-0.5">
            <span className="">{formatReference(result.transactionRef)}</span>
            <img
              src={copy}
              alt="copy"
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                copyToClipboard(result.transactionRef);
              }}
            />
          </span>
          <p className="font-semibold text-sm text-blackDark">
            {result.transactionType}{" "}
          </p>
          <p className="font-normal text-sm text-blackDark">
            {result.beneficiary?.accountName ?? result.beneficiary?.accountNo}{" "}
          </p>

          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.transactionDate))} <br />{" "}
            {convertTime(new Date(result.transactionDate))}
          </p>

          <Status status={result.status.toLowerCase()} />
        </Link>
      ))}

      {data?.results?.length > 0 && (
        <Pagination data={data} prev={prev} next={next} />
      )}
    </>
  );
}
