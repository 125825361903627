import close from "assets/close.svg";

import Button from "components/Button";
import Select from "components/inputs/Select";
import { ChangeEvent } from "react";

interface FilterProps {
  onClose: () => void;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  filterTerm: string;
}

const statuses = [
  "Select All",
  "Submitted",
  "Incomplete",
  "Assessment form filled",
  "Scheduled appointment with a consultant",
  "Met with consultant",
  "Subscribe to service",
  "Customer Not Ready",
  "Migration Journey ongoing",
  "Migration Journey Completed",
  "Recommendation complete",
];
const Filter = ({ onClose, onChange, filterTerm }: FilterProps) => {
  return (
    <div className="bg-white w-[350px] m-auto mt-40 rounded overflow-auto">
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-1">
        <p className="text-black text-base font-bold">Set Status</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>

      <div className="p-4 border-b  border-btnInactive">
        <Select
          label="Status"
          id="assignedType"
          name="assignedType"
          value={filterTerm}
          onChange={onChange}
          data={statuses.map((status) => ({
            label: status,
            value: status === "Select All" ? "" : status,
          }))}
        />
      </div>

      <div className="px-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Apply" size="small" onClick={onClose} />
      </div>
    </div>
  );
};

export default Filter;
