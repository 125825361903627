import { AnyObject } from "utils/types";

interface PaginationProps {
  data: AnyObject;
  prev: () => void;
  next: () => void;
}

const Pagination = ({ data, prev, next }: PaginationProps) => {

  // TODO: Refactor this by passing only the array, page, and totalPages as props
  return data?.results?.length > 0 ||
    data?.cards?.length > 0 ||
    data?.wallets?.length > 0 || data?.transactions?.length > 0 ? (
    <div className="py-8 px-12 bg-white rounded-b border-b border-x border-btnInactive flex justify-between items-center">
      <p className="text-coolGrey font-bold">
        Page {data?.page} of {data?.totalPages}
      </p>
      {data?.totalPages > 1 && (
        <div className="flex gap-4">
          {data?.page > 1 && (
            <span
              role="button"
              onClick={prev}
              className="text-coolGrey font-bold cursor-pointer"
            >
              Prev
            </span>
          )}
          {data?.totalPages > data?.page && (
            <span
              role="button"
              onClick={next}
              className="text-coolGrey font-bold cursor-pointer"
            >
              Next
            </span>
          )}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Pagination;
