import { useAuthContext } from "hooks/useAuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import { RouteLinks } from "utils/routes";
import { startCase } from "lodash";
import logout from "assets/log-out.svg";
import { useEffect, useRef, useState } from "react";

function SideBar() {
  const { user } = useAuthContext();
  const [links, setLinks] = useState(RouteLinks);
  const merged: React.MutableRefObject<
    {
      name: string;
      path: string;
      icon: JSX.Element;
    }[]
  > = useRef(RouteLinks);

  const navigate = useNavigate();

  const canViewCards = user?.permissions?.includes("view:cards");
  const canViewUsers = user?.permissions?.includes("view:user:all");
  const canViewFxManagements = user?.permissions?.includes("view:fx");
  const canViewBillPayment = user?.permissions?.includes("view:billPayments");
  const canViewLoans = user?.permissions?.includes("view:loans");
  const canViewAdministrator = user?.permissions?.includes(
    "view:administrators"
  );
  const canViewSettings = user?.permissions?.includes("view:settings");
  const canViewAnalytics = user?.permissions?.includes("view:analytics");
  const canViewAccounts = user?.permissions?.includes("view:accounts");
  const canViewTransactions = user?.permissions?.includes(
    "view:transactions:all"
  );
  const canViewKYC = user?.permissions?.includes("view:kyc");
  const canViewMigration = user?.permissions?.includes(
    "view:migrationAssessment"
  );

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (!canViewCards) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Cards"),
        ];
      }

      if (!canViewAccounts) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Accounts"),
        ];
      }

      if (!canViewUsers) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Users"),
        ];
      }

      if (!canViewFxManagements) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "FX Management"),
        ];
      }

      if (!canViewBillPayment) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Bill Payment"),
        ];
      }

      if (!canViewLoans) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Loans"),
        ];
      }

      if (!canViewFxManagements) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Fees"),
        ];
      }

      if (!canViewAdministrator) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Administrators"),
        ];
      }

      if (!canViewSettings) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Settings"),
        ];
      }

      if (!canViewTransactions) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Transactions"),
        ];
      }
      if (!canViewKYC) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "KYC"),
        ];
      }

      if (!canViewMigration) {
        merged.current = [
          ...merged.current.filter(
            (link) => link.name !== "Migration Assessment"
          ),
        ];
      }

      if (!canViewAnalytics) {
        merged.current = [
          ...merged.current.filter((link) => link.name !== "Analytics"),
        ];
      }
    }
    setLinks(merged.current);
  }, [
    user,
    canViewCards,
    canViewAccounts,
    canViewTransactions,
    canViewKYC,
    canViewAnalytics,
    canViewAdministrator,
    canViewBillPayment,
    canViewFxManagements,
    canViewLoans,
    canViewUsers,
    canViewSettings,
    canViewMigration,
  ]);
  const onLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  
  return (
    <div className="w-[209px] bg-blackDark text-page-bg h-full py-5 px-4 overflow-auto fixed">
      <div className="tracking-tight py-5 px-4 bg-navHero rounded flex flex-col justify-center">
        <p className="font-medium text-white">
          {user?.firstName} {user?.lastName}
        </p>
        <p className="text-xs text-white opacity-60 font-medium">
          {startCase(user?.role)}
        </p>
      </div>

      <div className="mt-10">
        {links.map((link) => {
          return (
            <NavLink
              key={link.path}
              to={link.path}
              className={({ isActive }) =>
                `flex items-center gap-6 px-3 py-2 my-4 font-normal text-sm tracking-wide ${
                  isActive ? "!text-white" : "text-linkText opacity-70"
                }`
              }
            >
              {link.icon}
              {link.name}
            </NavLink>
          );
        })}
      </div>
      <div
        onClick={onLogout}
        className="flex items-center gap-6 px-3 py-2 mt-16 cursor-pointer font-normal text-sm tracking-wide text-linkText opacity-70"
      >
        <img src={logout} alt="logout" /> Logout
      </div>
    </div>
  );
}

export default SideBar;
