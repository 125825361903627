import Button from "components/Button";
import { AnyObject, object, string } from "yup";
import close from "assets/close.svg";
import TextArea from "components/inputs/TextArea";
import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useKYCHandlers } from "../useKycHandlers";

interface RejectProps {
  onClose: () => void;
  data: AnyObject;
}

const RejectKYC = ({ onClose, data }: RejectProps) => {
  const { handleRejectKYC, isError, error, loading } = useKYCHandlers(onClose);

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<{ rejectionReason: string; approved: boolean; requestId: string }>({
      initialValues: {
        rejectionReason: "",
        approved: false,
        requestId: data?.id,
      },
      validationSchema: object({
        rejectionReason: string().required("A reason must be provided"),
      }),
      onSubmit: (values) => {
        handleRejectKYC(values);
      },
    });
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-48 rounded"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Decline Tier Upgrade</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b border-btnInactive">
        <TextArea
          id="rejectionReason"
          label="Reason"
          name="rejectionReason"
          value={values.rejectionReason}
          onChange={handleChange}
          error={`${
            !!errors["rejectionReason"] && !!touched["rejectionReason"]
          }`}
          errorMessage={errors["rejectionReason"]}
          onBlur={handleBlur}
        />
      </div>

      <div className="p-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Decline" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default RejectKYC;
