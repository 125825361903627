import close from "assets/close.svg";

import { useMigrationActions } from "../useMigrationHandlers";
import { AnyObject } from "utils/types";
import Button from "components/Button";
import { countries } from "utils/countries";
import Select from "components/inputs/Select";
import { ChangeEvent, FormEvent, useState } from "react";

interface RecommendationProps {
  onClose: () => void;
  data: AnyObject;
}

const pathways = [
  { label: "Study", value: "Study" },
  { label: "Work", value: "Work" },
  { label: "Permanent Residency", value: "Permanent Residency" },
];

const Recommendation = ({ onClose, data }: RecommendationProps) => {
  const {handleRecommendation, loading } = useMigrationActions(onClose);

  const [fields, setFields] = useState([
    {
      country: "",
      pathway: "",
    },
  ]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>, i: number) => {
    const { name, value } = e.target;
    setFields((prev) => {
      const newOpts = [...prev];
      (newOpts[i] as AnyObject)[name] = value;
      return newOpts;
    });
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
        recommendations: fields,
        leadId: data.id,
    }
    handleRecommendation(payload);
  };

  const addMore = () => {
    const currArr = [...fields];
    currArr.push({ country: "", pathway: "" });
    setFields(currArr);
  };

  const removeItem = (i: number) => {
    const currOpts = [...fields];
    const newOpts = currOpts.filter((_, idx) => idx !== i);
    setFields(newOpts);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-20 rounded overflow-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-1">
        <p className="text-black text-base font-bold">Consultant</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>
      <div className="p-4 border-b  border-btnInactive">
        {fields.map((field, i) => (
          <div key={i}>
            <div className="flex justify-between items-center gap-4">
              <Select
                label="Select Country"
                id="country"
                name="country"
                value={field.country}
                onChange={(e) => handleChange(e, i)}
                placeholder={"Select country"}
                data={countries.map((country) => ({
                  label: country,
                  value: country,
                }))}
              />

              <Select
                label="Add Pathway"
                id="pathway"
                name="pathway"
                value={field.pathway}
                onChange={(e) => handleChange(e, i)}
                placeholder={"Select pathway"}
                data={pathways}
              />
            </div>
            {fields.length > 1 && (
              <span
                onClick={() => removeItem(i)}
                className="text-xs flex justify-end cursor-pointer text-danger -mt-2"
              >
                Remove
              </span>
            )}
          </div>
        ))}

        <Button
          onClick={addMore}
          label="Add more"
          size="small"
          variant="other"
        />
      </div>

      <div className="px-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Give Recommendation" size="medium" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default Recommendation;
